import { NavigationContainer as ReactNavigationContainer, createNavigationContainerRef } from '@react-navigation/native';

import { useTheme } from '@contexts';

import linking from './linking';
import { RootNavigator, RootNavigatorParamList } from './RootNavigator';

function NavigationContainer(): JSX.Element {
    const { theme } = useTheme();

    const navigationRef = createNavigationContainerRef<RootNavigatorParamList>();
    return (
        <ReactNavigationContainer
            ref={navigationRef}
            theme={{ colors: theme.navigation, dark: theme.mode === 'dark' }}
            linking={linking}
            documentTitle={{
                formatter: (options) => {
                    return options != null && options.title != null ? `Tarsilla - ${options.title}` : 'Tarsilla';
                },
            }}
        >
            <RootNavigator />
        </ReactNavigationContainer>
    );
}

export default NavigationContainer;
