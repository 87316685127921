import { createContext, PropsWithChildren, useContext as useReactContext, useState } from 'react';

type ContextProps = {
    loading: boolean;
    setLoading: (value: boolean) => void;
    initialLoading: boolean;
    setInitialLoading: (value: boolean) => void;
};

const Context = createContext<ContextProps | null>(null);

function Provider({ children }: PropsWithChildren): JSX.Element {
    const [initialLoading, setInitialLoading] = useState<boolean>(true);
    const [loading, setLoading] = useState<boolean>(false);

    return <Context.Provider value={{ loading, setLoading, initialLoading, setInitialLoading }}>{children}</Context.Provider>;
}

export default Provider;

export function useContext(): ContextProps {
    const context = useReactContext(Context);
    if (!context) {
        throw new Error('error loading context');
    }
    return context;
}
