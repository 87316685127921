import { StyleSheet, TextStyle } from 'react-native';

import { useTheme } from '@contexts';

type StylesProps = {
    text: TextStyle;
    dateText: TextStyle;
};

function useStyles(): StylesProps {
    const { theme } = useTheme();

    return StyleSheet.create<StylesProps>({
        text: {
            fontFamily: theme.text.fontFamily,
            fontSize: 11,
            color: '#FFFFFF',
            fontWeight: '400',
            lineHeight: 13,
        },
        dateText: {
            fontFamily: theme.text.fontFamily,
            fontSize: 12,
            color: '#FFFFFF',
            fontWeight: '700',
        },
    });
}

export default useStyles;
