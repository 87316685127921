import { createContext, PropsWithChildren, useContext as useReactContext } from 'react';

import * as ExpoSecureStore from 'expo-secure-store';
import { Platform } from 'react-native';

const useExpoSecureStore = Platform.select({ web: false, default: true });

const setStorageItem = useExpoSecureStore
    ? (key: string, value: string | null) => (value ? ExpoSecureStore.setItemAsync(key, value) : ExpoSecureStore.deleteItemAsync(key))
    : (key: string, value: string | null) => (value ? Promise.resolve(sessionStorage.setItem(key, value)) : Promise.resolve(sessionStorage.removeItem(key)));
const getStorageItem = useExpoSecureStore ? ExpoSecureStore.getItemAsync : (key: string) => Promise.resolve(sessionStorage.getItem(key));

type ContextProps = {
    getToken: () => Promise<string | null>;
    setToken: (value: string | null) => Promise<void>;
};

const Context = createContext<ContextProps | null>(null);

function Provider({ children }: PropsWithChildren): JSX.Element {
    const value = {
        getToken: () => getStorageItem(useExpoSecureStore ? 'session' : '@session'),
        setToken: (value: string | null) => setStorageItem(useExpoSecureStore ? 'session' : '@session', value),
    };
    return <Context.Provider value={value}>{children}</Context.Provider>;
}

export default Provider;

export function useContext(): ContextProps {
    const context = useReactContext(Context);
    if (!context) {
        throw new Error('error session context');
    }
    return context;
}
