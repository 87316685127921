import { StyleSheet, TextStyle } from 'react-native';

import { useTheme } from '@contexts';

type StylesProps = {
    code: TextStyle;
    title: TextStyle;
    icon: TextStyle;
    patrimonyText: TextStyle;
};

function useStyles(): StylesProps {
    const { theme } = useTheme();

    return StyleSheet.create<StylesProps>({
        code: {
            fontFamily: theme.maintenanceKanbanCard.codeFontFamily,
            fontSize: theme.maintenanceKanbanCard.codeFontSize,
            height: theme.maintenanceKanbanCard.codeLineHeight,
            color: theme.maintenanceKanbanCard.codeColor,
            marginBottom: 12,
        },
        title: {
            fontFamily: theme.maintenanceKanbanCard.titleFontFamily,
            fontSize: theme.maintenanceKanbanCard.titleFontSize,
            lineHeight: theme.maintenanceKanbanCard.titleLineHeight,
            color: theme.maintenanceKanbanCard.titleColor,
        },
        icon: {
            alignSelf: 'flex-end',
            color: theme.maintenanceKanbanCard.iconColor,
            fontSize: theme.maintenanceKanbanCard.iconSize,
        },
        patrimonyText: {
            fontFamily: theme.maintenanceKanbanCard.patrimonyFontFamily,
            fontSize: theme.maintenanceKanbanCard.patrimonyFontSize,
            lineHeight: theme.maintenanceKanbanCard.patrimonyLineHeight,
            color: theme.maintenanceKanbanCard.patrimonyColor,
            marginTop: 12,
            textAlign: 'center',
            alignSelf: 'flex-start',
            borderRadius: 10,
            padding: 4,
        },
    });
}

export default useStyles;
