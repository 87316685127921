import { LinkingOptions } from '@react-navigation/native';
import { createURL } from 'expo-linking';

import { RootNavigatorParamList } from './RootNavigator';

const linking: LinkingOptions<RootNavigatorParamList> = {
    prefixes: [createURL('/')],
    config: {
        initialRouteName: 'SignedOutNavigator',
        screens: {
            SignedOutNavigator: {
                initialRouteName: 'AboutUs',
                path: '',
                screens: {
                    AboutUs: {
                        path: '',
                    },
                    Contact: {
                        path: 'contact',
                    },
                },
            },
            PopulationNavigator: {
                initialRouteName: 'AboutUs',
                path: 'pop',
                screens: {
                    AboutUs: {
                        path: '',
                    },
                    Inventory: {
                        path: 'inventory',
                    },
                    Map: {
                        path: 'map',
                    },
                    Glossary: {
                        path: 'glossary',
                    },
                    Contact: {
                        path: 'contact',
                    },
                },
            },
            InstitutionDirectorNavigator: {
                initialRouteName: 'Inventory',
                path: 'dir',
                screens: {
                    Inventory: {
                        path: 'inventory',
                    },
                    Inspection: {
                        path: 'inspection',
                    },
                    Maintenance: {
                        path: 'maintenance',
                    },
                    Glossary: {
                        path: 'glossary',
                    },
                },
            },
            InstitutionCoordinatorNavigator: {
                initialRouteName: 'Inventory',
                path: 'coo',
                screens: {
                    Inventory: {
                        path: 'inventory',
                    },
                    Inspection: {
                        path: 'inspection',
                    },
                    Maintenance: {
                        path: 'maintenance',
                    },
                    Glossary: {
                        path: 'glossary',
                    },
                },
            },
            InstitutionTechnicianNavigator: {
                initialRouteName: 'Inventory',
                path: 'tec',
                screens: {
                    Inventory: {
                        path: 'inventory',
                    },
                    Inspection: {
                        path: 'inspection',
                    },
                    Maintenance: {
                        path: 'maintenance',
                    },
                    Glossary: {
                        path: 'glossary',
                    },
                },
            },
            InstitutionCaretakerNavigator: {
                initialRouteName: 'Inventory',
                path: 'car',
                screens: {
                    Inventory: {
                        path: 'inventory',
                    },
                    Inspection: {
                        path: 'inspection',
                    },
                    Maintenance: {
                        path: 'maintenance',
                    },
                    Glossary: {
                        path: 'glossary',
                    },
                },
            },
        },
    },
};

export default linking;
