/* eslint-disable import/no-duplicates */
import 'react-native-gesture-handler';
import { StatusBar } from 'expo-status-bar';
import { View } from 'react-native';
import { ToastProvider } from 'react-native-fast-toast';
import { SafeAreaProvider } from 'react-native-safe-area-context';

import { AuthContextProvider, LoadingContextProvider, SessionContextProvider, ThemeContextProvider, TranslationContextProvider } from '@contexts';

import NavigationContainer from './navigation/NavigationContainer';

export default function App(): JSX.Element {
    return (
        <SafeAreaProvider>
            <TranslationContextProvider>
                <ThemeContextProvider>
                    <View style={{ flex: 1, overflow: 'hidden', minWidth: 320 }}>
                        <StatusBar translucent />
                        <SessionContextProvider>
                            <ToastProvider>
                                <LoadingContextProvider>
                                    <AuthContextProvider>
                                        <NavigationContainer />
                                    </AuthContextProvider>
                                </LoadingContextProvider>
                            </ToastProvider>
                        </SessionContextProvider>
                    </View>
                </ThemeContextProvider>
            </TranslationContextProvider>
        </SafeAreaProvider>
    );
}
