const fonts = {
    light: 'OpenSans_300Light',
    regular: 'OpenSans_400Regular',
    medium: 'OpenSans_500Medium',
    semiBold: 'OpenSans_600SemiBold',
    bold: 'OpenSans_700Bold',
    extraBold: 'OpenSans_800ExtraBold',
};

export default fonts;
