import { Dispatch, FunctionComponent, SetStateAction, useEffect, useState } from 'react';

import { MaterialCommunityIcons } from '@expo/vector-icons';
import { useIsFocused } from '@react-navigation/native';
import { useIntl } from 'react-intl';
import { Platform, Text, TextInput, TouchableOpacity, View, useWindowDimensions } from 'react-native';

import { Dropdown } from '@base_components';
import { useAuth, useLoading, useTheme } from '@contexts';
import { HttpClient } from '@domain';
import { ImmovablePatrimonyTypeEnum, MaterialPatrimonyAdministrativeInstanceTypeEnum, MaterialPatrimonyLegalProtectionTypeEnum, MaterialPatrimonyTypeEnum, MovablePatrimonyTypeEnum } from '@enum';
import { useDebounce } from '@hooks';

type HeadProps = {
    title: string;
    items: { label: string; value: string }[];
    selected: string | null;
    setSelected: Dispatch<SetStateAction<string | null>>;
    search: string | null;
    setSearch: Dispatch<SetStateAction<string | null>>;
    materialPatrimonyType: MaterialPatrimonyTypeEnum | null;
    setMaterialPatrimonyType: Dispatch<SetStateAction<MaterialPatrimonyTypeEnum | null>>;
    materialPatrimonyMovableType: MovablePatrimonyTypeEnum[] | null;
    setMaterialPatrimonyMovableType: Dispatch<SetStateAction<MovablePatrimonyTypeEnum[] | null>>;
    materialPatrimonyImmovableType: ImmovablePatrimonyTypeEnum[] | null;
    setMaterialPatrimonyImmovableType: Dispatch<SetStateAction<ImmovablePatrimonyTypeEnum[] | null>>;
    materialPatrimonyLegalProtection: (MaterialPatrimonyLegalProtectionTypeEnum | 'NONE')[] | null;
    setMaterialPatrimonyLegalProtection: Dispatch<SetStateAction<(MaterialPatrimonyLegalProtectionTypeEnum | 'NONE')[] | null>>;
    materialPatrimonyAdministrativeInstance: MaterialPatrimonyAdministrativeInstanceTypeEnum[] | null;
    setMaterialPatrimonyAdministrativeInstance: Dispatch<SetStateAction<MaterialPatrimonyAdministrativeInstanceTypeEnum[] | null>>;
    materialPatrimonyAvailable: string[] | null;
    setMaterialPatrimonyAvailable: Dispatch<SetStateAction<string[] | null>>;
};

function Head({
    title,
    items,
    selected,
    setSelected,
    search,
    setSearch,
    materialPatrimonyType,
    setMaterialPatrimonyType,
    materialPatrimonyMovableType,
    setMaterialPatrimonyMovableType,
    materialPatrimonyImmovableType,
    setMaterialPatrimonyImmovableType,
    materialPatrimonyLegalProtection,
    setMaterialPatrimonyLegalProtection,
    materialPatrimonyAdministrativeInstance,
    setMaterialPatrimonyAdministrativeInstance,
    materialPatrimonyAvailable,
    setMaterialPatrimonyAvailable,
}: HeadProps): JSX.Element {
    const { formatMessage } = useIntl();
    const { width } = useWindowDimensions();
    const { theme } = useTheme();

    const [filterOpen, setFilterOpen] = useState(false);

    return (
        <View
            style={{
                flexDirection: 'column',
                backgroundColor: theme.inventoryFilter.backgroundColor,
                paddingHorizontal: width < 500 ? 8 : width < 752 ? 16 : 32,
                paddingVertical: 8,
                zIndex: 1,
            }}
        >
            <View style={{ flexDirection: 'row', justifyContent: 'space-between', paddingBottom: filterOpen ? 8 : undefined }}>
                <View style={{ width: 24 }} />
                <View style={{ height: Platform.OS === 'web' ? 40 : 32 }}>
                    <Text
                        style={{
                            color: theme.inventoryFilter.title.textColor,
                            fontSize: theme.inventoryFilter.title.fontSize,
                            lineHeight: theme.inventoryFilter.title.fontSize,
                            fontFamily: theme.inventoryFilter.title.fontFamily,
                        }}
                    >
                        {formatMessage({ id: title })}
                    </Text>
                </View>
                <View style={{ width: 24, alignSelf: 'flex-end' }}>
                    <TouchableOpacity onPress={() => setFilterOpen(!filterOpen)}>
                        <MaterialCommunityIcons
                            name={filterOpen ? 'filter-variant-minus' : 'filter-variant-plus'}
                            size={24}
                            color={
                                (materialPatrimonyMovableType && materialPatrimonyMovableType.length > 0) ||
                                (materialPatrimonyImmovableType && materialPatrimonyImmovableType.length > 0) ||
                                (materialPatrimonyLegalProtection && materialPatrimonyLegalProtection.length > 0) ||
                                (materialPatrimonyAdministrativeInstance && materialPatrimonyAdministrativeInstance.length > 0) ||
                                (materialPatrimonyAvailable && materialPatrimonyAvailable.length > 0)
                                    ? '#BBD479'
                                    : '#FFFFFF'
                            }
                        />
                    </TouchableOpacity>
                </View>
            </View>
            {filterOpen && (
                <View>
                    <View style={{ flexDirection: width < 500 ? 'column' : 'row', justifyContent: width < 500 ? 'center' : 'space-between', alignItems: 'center', paddingBottom: 8, zIndex: 2 }}>
                        <View style={{ width: width < 500 ? '100%' : '20%', zIndex: 3 }}>
                            <Dropdown
                                type="rounded"
                                value={selected}
                                setValue={setSelected}
                                items={items}
                                placeholder={formatMessage({ id: 'pages.inventory_filter.city' })}
                                color="#FFFFFF"
                                selectedColor="#BBD479"
                                backgroundColor="#442772"
                                fontSize={theme.inventoryFilter.search.fontSize}
                                fontFamily={theme.inventoryFilter.search.fontFamily}
                                unselectable={false}
                                containerStyle={{
                                    width: '100%',
                                    maxWidth: width > 499 ? 200 : undefined,
                                }}
                            />
                        </View>
                        {width > 499 && (
                            <View
                                style={{
                                    backgroundColor: theme.inventoryFilter.filterButton.backgroundColor,
                                    borderColor: theme.inventoryFilter.filterButton.backgroundColor,
                                    borderWidth: 2,
                                    borderRadius: 50,
                                    paddingHorizontal: 8,
                                    paddingVertical: 4,
                                    zIndex: 2,
                                    marginTop: width < 500 ? 8 : undefined,
                                }}
                            >
                                <Text
                                    style={{
                                        color: theme.inventoryFilter.filterButton.textColor,
                                        fontSize: theme.inventoryFilter.filterButton.fontSize,
                                        //lineHeight: theme.inventoryFilter.filterButton.fontSize,
                                        fontFamily: theme.inventoryFilter.filterButton.fontFamily,
                                    }}
                                >
                                    {formatMessage({ id: 'pages.inventory_filter.enum.patrimony-type.MATERIAL' })}
                                </Text>
                            </View>
                        )}
                        <TextInput
                            style={{
                                backgroundColor: theme.inventoryFilter.search.backgroundColor,
                                borderColor: theme.inventoryFilter.search.borderColor,
                                borderWidth: 2,
                                borderRadius: 50,
                                paddingHorizontal: 8,
                                paddingVertical: 2,
                                color: theme.inventoryFilter.search.textColor,
                                fontSize: theme.inventoryFilter.search.fontSize,
                                //lineHeight: theme.inventoryFilter.search.lineHeight,
                                fontFamily: theme.inventoryFilter.search.fontFamily,
                                width: width < 500 ? '100%' : '20%',
                                maxWidth: width > 499 ? 200 : undefined,
                                zIndex: 2,
                                marginTop: width < 500 ? 8 : undefined,
                            }}
                            onChangeText={setSearch}
                            value={search ?? ''}
                            placeholder={formatMessage({ id: 'pages.inventory_filter.search' })}
                            placeholderTextColor={theme.inventoryFilter.search.textColor}
                        />
                        {width < 500 && (
                            <View
                                style={{
                                    backgroundColor: theme.inventoryFilter.filterButton.backgroundColor,
                                    borderColor: theme.inventoryFilter.filterButton.backgroundColor,
                                    borderWidth: 2,
                                    borderRadius: 50,
                                    paddingHorizontal: 8,
                                    paddingVertical: 4,
                                    zIndex: 2,
                                    marginTop: width < 500 ? 8 : undefined,
                                }}
                            >
                                <Text
                                    style={{
                                        color: theme.inventoryFilter.filterButton.textColor,
                                        fontSize: theme.inventoryFilter.filterButton.fontSize,
                                        //lineHeight: theme.inventoryFilter.filterButton.lineHeight,
                                        fontFamily: theme.inventoryFilter.filterButton.fontFamily,
                                    }}
                                >
                                    {formatMessage({ id: 'pages.inventory_filter.enum.patrimony-type.MATERIAL' })}
                                </Text>
                            </View>
                        )}
                    </View>
                    <View style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                        <TouchableOpacity
                            onPress={() => {
                                setMaterialPatrimonyType(null);
                                setMaterialPatrimonyMovableType(null);
                                setMaterialPatrimonyImmovableType(null);
                                setMaterialPatrimonyLegalProtection(null);
                                setMaterialPatrimonyAdministrativeInstance(null);
                                setMaterialPatrimonyAvailable(null);
                            }}
                            style={{
                                backgroundColor: !materialPatrimonyType ? theme.inventoryFilter.filterButton.backgroundColor : undefined,
                                borderColor: !materialPatrimonyType ? theme.inventoryFilter.filterButton.backgroundColor : '#FFFFFF',
                                borderWidth: 2,
                                borderRadius: 50,
                                paddingHorizontal: 8,
                                paddingVertical: 4,
                            }}
                        >
                            <Text
                                style={{
                                    color: !materialPatrimonyType ? theme.inventoryFilter.filterButton.textColor : '#FFFFFF',
                                    fontSize: theme.inventoryFilter.filterButton.fontSize,
                                    //lineHeight: theme.inventoryFilter.filterButton.lineHeight,
                                    fontFamily: theme.inventoryFilter.filterButton.fontFamily,
                                    alignSelf: 'center',
                                }}
                            >
                                {formatMessage({ id: 'pages.inventory_filter.all' })}
                            </Text>
                        </TouchableOpacity>
                        {Object.values(MaterialPatrimonyTypeEnum).map((type, index) => (
                            <TouchableOpacity
                                key={index}
                                onPress={() => {
                                    setMaterialPatrimonyType(type);
                                    setMaterialPatrimonyMovableType(null);
                                    setMaterialPatrimonyImmovableType(null);
                                    setMaterialPatrimonyLegalProtection(null);
                                    setMaterialPatrimonyAdministrativeInstance(null);
                                    setMaterialPatrimonyAvailable(null);
                                }}
                                style={{
                                    backgroundColor: materialPatrimonyType === type ? theme.inventoryFilter.filterButton.backgroundColor : undefined,
                                    borderColor: materialPatrimonyType === type ? theme.inventoryFilter.filterButton.backgroundColor : '#FFFFFF',
                                    borderWidth: 2,
                                    borderRadius: 50,
                                    paddingHorizontal: 8,
                                    paddingVertical: 4,
                                    marginLeft: 8,
                                }}
                            >
                                <Text
                                    style={{
                                        color: materialPatrimonyType === type ? theme.inventoryFilter.filterButton.textColor : '#FFFFFF',
                                        fontSize: theme.inventoryFilter.filterButton.fontSize,
                                        //lineHeight: theme.inventoryFilter.filterButton.lineHeight,
                                        fontFamily: theme.inventoryFilter.filterButton.fontFamily,
                                        alignSelf: 'center',
                                    }}
                                >
                                    {formatMessage({ id: `pages.inventory_filter.enum.material-patrimony-type.${type}` })}
                                </Text>
                            </TouchableOpacity>
                        ))}
                    </View>
                    {(width < 500 || width > 751) && materialPatrimonyType && (
                        <View style={{ flexDirection: width < 500 ? 'column' : 'row', alignItems: width > 499 ? 'flex-start' : undefined, marginTop: 8 }}>
                            {materialPatrimonyType === MaterialPatrimonyTypeEnum.IMMOVABLE && (
                                <Dropdown
                                    type={width < 500 ? 'rounded-highlighted' : width < 752 ? 'line-highlighted' : 'arrow-highlighted'}
                                    value={materialPatrimonyImmovableType ?? null}
                                    setValue={setMaterialPatrimonyImmovableType}
                                    items={Object.values(ImmovablePatrimonyTypeEnum).map((item) => ({
                                        value: item,
                                        label: formatMessage({ id: `pages.inventory_filter.enum.immovable-patrimony-type.${item}` }),
                                    }))}
                                    placeholder={formatMessage({ id: 'pages.inventory_filter.immovable-patrimony.classification' })}
                                    color="#FFFFFF"
                                    selectedColor="#BBD479"
                                    backgroundColor="#442772"
                                    fontSize={theme.inventoryFilter.search.fontSize}
                                    fontFamily={theme.inventoryFilter.search.fontFamily}
                                    containerStyle={{
                                        marginRight: width > 499 ? 16 : undefined,
                                        width: width > 499 ? 123 : undefined,
                                        zIndex: 5,
                                    }}
                                    dropDownContainerStyle={{
                                        marginTop: width > 751 ? 9 : undefined,
                                        minWidth: width > 499 ? 123 : undefined,
                                    }}
                                    multiple
                                />
                            )}
                            {materialPatrimonyType === MaterialPatrimonyTypeEnum.MOVABLE && (
                                <Dropdown
                                    type={width < 500 ? 'rounded-highlighted' : width < 752 ? 'line-highlighted' : 'arrow-highlighted'}
                                    value={materialPatrimonyMovableType ?? null}
                                    setValue={setMaterialPatrimonyMovableType}
                                    items={Object.values(MovablePatrimonyTypeEnum).map((item) => ({
                                        value: item,
                                        label: formatMessage({ id: `pages.inventory_filter.enum.movable-patrimony-type.${item}` }),
                                    }))}
                                    placeholder={formatMessage({ id: 'pages.inventory_filter.movable-patrimony.classification' })}
                                    color="#FFFFFF"
                                    selectedColor="#BBD479"
                                    backgroundColor="#442772"
                                    fontSize={theme.inventoryFilter.search.fontSize}
                                    fontFamily={theme.inventoryFilter.search.fontFamily}
                                    containerStyle={{
                                        marginRight: width > 499 ? 16 : undefined,
                                        width: width > 499 ? 123 : undefined,
                                        zIndex: 5,
                                    }}
                                    dropDownContainerStyle={{
                                        marginTop: width > 751 ? 9 : undefined,
                                        minWidth: width > 499 ? 123 : undefined,
                                    }}
                                    multiple
                                />
                            )}
                            <Dropdown
                                type={width < 500 ? 'rounded-highlighted' : width < 752 ? 'line-highlighted' : 'arrow-highlighted'}
                                value={materialPatrimonyLegalProtection ?? null}
                                setValue={setMaterialPatrimonyLegalProtection}
                                items={['NONE', ...Object.values(MaterialPatrimonyLegalProtectionTypeEnum)].map((item) => ({
                                    value: item,
                                    label: formatMessage({ id: `pages.inventory_filter.enum.material-patrimony-legal-protection-type.${item}` }),
                                }))}
                                placeholder={formatMessage({ id: 'pages.inventory_filter.immovable-patrimony.legal-protection' })}
                                color="#FFFFFF"
                                selectedColor="#BBD479"
                                backgroundColor="#442772"
                                fontSize={theme.inventoryFilter.search.fontSize}
                                fontFamily={theme.inventoryFilter.search.fontFamily}
                                containerStyle={{
                                    marginTop: width < 500 ? 8 : undefined,
                                    marginRight: width > 499 ? 16 : undefined,
                                    width: width > 499 ? 134 : undefined,
                                    zIndex: 3,
                                }}
                                dropDownContainerStyle={{
                                    marginTop: width > 751 ? 9 : undefined,
                                    minWidth: width > 499 ? 134 : undefined,
                                }}
                                multiple
                            />
                            <Dropdown
                                type={width < 500 ? 'rounded-highlighted' : width < 752 ? 'line-highlighted' : 'arrow-highlighted'}
                                value={materialPatrimonyAdministrativeInstance ?? null}
                                setValue={setMaterialPatrimonyAdministrativeInstance}
                                items={Object.values(MaterialPatrimonyAdministrativeInstanceTypeEnum).map((item) => ({
                                    value: item,
                                    label: formatMessage({ id: `pages.inventory_filter.enum.material-patrimony-administrative-instance-type.${item}` }),
                                }))}
                                placeholder={formatMessage({ id: 'pages.inventory_filter.immovable-patrimony.administrative-instance' })}
                                color="#FFFFFF"
                                selectedColor="#BBD479"
                                backgroundColor="#442772"
                                fontSize={theme.inventoryFilter.search.fontSize}
                                fontFamily={theme.inventoryFilter.search.fontFamily}
                                containerStyle={{
                                    marginTop: width < 500 ? 8 : undefined,
                                    marginRight: width > 499 ? 16 : undefined,
                                    width: width > 499 ? 217 : undefined,
                                    zIndex: 2,
                                }}
                                dropDownContainerStyle={{
                                    marginTop: width > 751 ? 9 : undefined,
                                    minWidth: width > 499 ? 217 : undefined,
                                }}
                                multiple
                            />
                            {materialPatrimonyType === MaterialPatrimonyTypeEnum.MOVABLE && (
                                <Dropdown
                                    type={width < 500 ? 'rounded-highlighted' : width < 752 ? 'line-highlighted' : 'arrow-highlighted'}
                                    value={materialPatrimonyAvailable}
                                    setValue={setMaterialPatrimonyAvailable}
                                    items={[
                                        { value: 'true', label: 'Sim' },
                                        { value: 'false', label: 'Não' },
                                    ]}
                                    placeholder={formatMessage({ id: 'pages.inventory_filter.movable-patrimony.missing' })}
                                    color="#FFFFFF"
                                    selectedColor="#BBD479"
                                    backgroundColor="#442772"
                                    fontSize={theme.inventoryFilter.search.fontSize}
                                    fontFamily={theme.inventoryFilter.search.fontFamily}
                                    containerStyle={{
                                        marginTop: width < 500 ? 8 : undefined,
                                        marginRight: width > 499 ? 16 : undefined,
                                        width: width > 499 ? 167 : undefined,
                                        zIndex: 1,
                                    }}
                                    dropDownContainerStyle={{
                                        marginTop: width > 751 ? 9 : undefined,
                                        minWidth: width > 499 ? 167 : undefined,
                                    }}
                                    multiple
                                />
                            )}
                        </View>
                    )}
                    {width > 500 && width < 752 && materialPatrimonyType && (
                        <View>
                            <View style={{ flexDirection: 'row', alignItems: 'flex-start', marginTop: 8, zIndex: 3 }}>
                                {materialPatrimonyType === MaterialPatrimonyTypeEnum.IMMOVABLE && (
                                    <Dropdown
                                        type={width < 500 ? 'rounded-highlighted' : width < 752 ? 'line-highlighted' : 'arrow-highlighted'}
                                        value={materialPatrimonyImmovableType ?? null}
                                        setValue={setMaterialPatrimonyImmovableType}
                                        items={Object.values(ImmovablePatrimonyTypeEnum).map((item) => ({
                                            value: item,
                                            label: formatMessage({ id: `pages.inventory_filter.enum.immovable-patrimony-type.${item}` }),
                                        }))}
                                        placeholder={formatMessage({ id: 'pages.inventory_filter.immovable-patrimony.classification' })}
                                        color="#FFFFFF"
                                        selectedColor="#BBD479"
                                        backgroundColor="#442772"
                                        fontSize={theme.inventoryFilter.search.fontSize}
                                        fontFamily={theme.inventoryFilter.search.fontFamily}
                                        containerStyle={{
                                            marginRight: width > 499 ? 16 : undefined,
                                            width: width > 499 ? 123 : undefined,
                                            zIndex: 5,
                                        }}
                                        dropDownContainerStyle={{
                                            marginTop: width > 751 ? 9 : undefined,
                                            minWidth: width > 499 ? 123 : undefined,
                                        }}
                                        multiple
                                    />
                                )}
                                {materialPatrimonyType === MaterialPatrimonyTypeEnum.MOVABLE && (
                                    <Dropdown
                                        type={width < 500 ? 'rounded-highlighted' : width < 752 ? 'line-highlighted' : 'arrow-highlighted'}
                                        value={materialPatrimonyMovableType ?? null}
                                        setValue={setMaterialPatrimonyMovableType}
                                        items={Object.values(MovablePatrimonyTypeEnum).map((item) => ({
                                            value: item,
                                            label: formatMessage({ id: `pages.inventory_filter.enum.movable-patrimony-type.${item}` }),
                                        }))}
                                        placeholder={formatMessage({ id: 'pages.inventory_filter.movable-patrimony.classification' })}
                                        color="#FFFFFF"
                                        selectedColor="#BBD479"
                                        backgroundColor="#442772"
                                        fontSize={theme.inventoryFilter.search.fontSize}
                                        fontFamily={theme.inventoryFilter.search.fontFamily}
                                        containerStyle={{
                                            marginRight: width > 499 ? 16 : undefined,
                                            width: width > 499 ? 123 : undefined,
                                            zIndex: 5,
                                        }}
                                        dropDownContainerStyle={{
                                            marginTop: width > 751 ? 9 : undefined,
                                            minWidth: width > 499 ? 123 : undefined,
                                        }}
                                        multiple
                                    />
                                )}
                                <Dropdown
                                    type={width < 500 ? 'rounded-highlighted' : width < 752 ? 'line-highlighted' : 'arrow-highlighted'}
                                    value={materialPatrimonyLegalProtection ?? null}
                                    setValue={setMaterialPatrimonyLegalProtection}
                                    items={['NONE', ...Object.values(MaterialPatrimonyLegalProtectionTypeEnum)].map((item) => ({
                                        value: item,
                                        label: formatMessage({ id: `pages.inventory_filter.enum.material-patrimony-legal-protection-type.${item}` }),
                                    }))}
                                    placeholder={formatMessage({ id: 'pages.inventory_filter.immovable-patrimony.legal-protection' })}
                                    color="#FFFFFF"
                                    selectedColor="#BBD479"
                                    backgroundColor="#442772"
                                    fontSize={theme.inventoryFilter.search.fontSize}
                                    fontFamily={theme.inventoryFilter.search.fontFamily}
                                    containerStyle={{
                                        marginTop: width < 500 ? 8 : undefined,
                                        marginRight: width > 499 ? 16 : undefined,
                                        width: width > 499 ? 134 : undefined,
                                        zIndex: 3,
                                    }}
                                    dropDownContainerStyle={{
                                        marginTop: width > 751 ? 9 : undefined,
                                        minWidth: width > 499 ? 134 : undefined,
                                    }}
                                    multiple
                                />
                            </View>
                            <View style={{ flexDirection: 'row', alignItems: 'flex-start', marginTop: 8, zIndex: 2 }}>
                                <Dropdown
                                    type={width < 500 ? 'rounded-highlighted' : width < 752 ? 'line-highlighted' : 'arrow-highlighted'}
                                    value={materialPatrimonyAdministrativeInstance ?? null}
                                    setValue={setMaterialPatrimonyAdministrativeInstance}
                                    items={Object.values(MaterialPatrimonyAdministrativeInstanceTypeEnum).map((item) => ({
                                        value: item,
                                        label: formatMessage({ id: `pages.inventory_filter.enum.material-patrimony-administrative-instance-type.${item}` }),
                                    }))}
                                    placeholder={formatMessage({ id: 'pages.inventory_filter.immovable-patrimony.administrative-instance' })}
                                    color="#FFFFFF"
                                    selectedColor="#BBD479"
                                    backgroundColor="#442772"
                                    fontSize={theme.inventoryFilter.search.fontSize}
                                    fontFamily={theme.inventoryFilter.search.fontFamily}
                                    containerStyle={{
                                        marginTop: width < 500 ? 8 : undefined,
                                        marginRight: width > 499 ? 16 : undefined,
                                        width: width > 499 ? 217 : undefined,
                                        zIndex: 2,
                                    }}
                                    dropDownContainerStyle={{
                                        marginTop: width > 751 ? 9 : undefined,
                                        minWidth: width > 499 ? 217 : undefined,
                                    }}
                                    multiple
                                />
                                {materialPatrimonyType === MaterialPatrimonyTypeEnum.MOVABLE && (
                                    <Dropdown
                                        type={width < 500 ? 'rounded-highlighted' : width < 752 ? 'line-highlighted' : 'arrow-highlighted'}
                                        value={materialPatrimonyAvailable}
                                        setValue={setMaterialPatrimonyAvailable}
                                        items={[
                                            { value: 'true', label: 'Sim' },
                                            { value: 'false', label: 'Não' },
                                        ]}
                                        placeholder={formatMessage({ id: 'pages.inventory_filter.movable-patrimony.missing' })}
                                        color="#FFFFFF"
                                        selectedColor="#BBD479"
                                        backgroundColor="#442772"
                                        fontSize={theme.inventoryFilter.search.fontSize}
                                        fontFamily={theme.inventoryFilter.search.fontFamily}
                                        containerStyle={{
                                            marginTop: width < 500 ? 8 : undefined,
                                            marginRight: width > 499 ? 16 : undefined,
                                            width: width > 499 ? 167 : undefined,
                                            zIndex: 1,
                                        }}
                                        dropDownContainerStyle={{
                                            marginTop: width > 751 ? 9 : undefined,
                                            minWidth: width > 499 ? 167 : undefined,
                                        }}
                                        multiple
                                    />
                                )}
                            </View>
                        </View>
                    )}
                </View>
            )}
        </View>
    );
}

type InventoryFilterProps = {
    Component: FunctionComponent<InventoryFilterComponentProps>;
    title: string;
};

export type InventoryFilterComponentProps = {
    selected: string | null;
    search: string | null;
    materialPatrimonyType: MaterialPatrimonyTypeEnum | null;
    materialPatrimonyMovableType: MovablePatrimonyTypeEnum[] | null;
    materialPatrimonyImmovableType: ImmovablePatrimonyTypeEnum[] | null;
    materialPatrimonyLegalProtection: (MaterialPatrimonyLegalProtectionTypeEnum | 'NONE')[] | null;
    materialPatrimonyAdministrativeInstance: MaterialPatrimonyAdministrativeInstanceTypeEnum[] | null;
    materialPatrimonyAvailable: string[] | null;
};

type QueryProps = {
    id: string;
    city: {
        id: string;
        code: string;
    };
};

function Filter({ Component, title }: InventoryFilterProps): JSX.Element {
    const isFocused = useIsFocused();
    const { user } = useAuth();
    const { setLoading } = useLoading();

    const [selected, setSelected] = useState<string | null>(null);
    const [search, setSearch] = useState<string | null>(null);
    const debouncedSearch = useDebounce(search, 300);

    const [materialPatrimonyType, setMaterialPatrimonyType] = useState<MaterialPatrimonyTypeEnum | null>(null);
    const [materialPatrimonyMovableType, setMaterialPatrimonyMovableType] = useState<MovablePatrimonyTypeEnum[] | null>(null);
    const [materialPatrimonyImmovableType, setMaterialPatrimonyImmovableType] = useState<ImmovablePatrimonyTypeEnum[] | null>(null);
    const [materialPatrimonyLegalProtection, setMaterialPatrimonyLegalProtection] = useState<(MaterialPatrimonyLegalProtectionTypeEnum | 'NONE')[] | null>(null);
    const [materialPatrimonyAdministrativeInstance, setMaterialPatrimonyAdministrativeInstance] = useState<MaterialPatrimonyAdministrativeInstanceTypeEnum[] | null>(null);
    const [materialPatrimonyAvailable, setMaterialPatrimonyAvailable] = useState<string[] | null>(null);

    const [items, setItems] = useState<{ label: string; value: string; tarsillaCityId: string }[]>([]);

    async function getCities(client: HttpClient) {
        setLoading(true);
        const cities = await client.get<QueryProps[]>('/tarsilla_city');
        if (cities) {
            setItems(cities.map((tarsillaCity) => ({ label: tarsillaCity.city.code, value: tarsillaCity.city.id, tarsillaCityId: tarsillaCity.id })));
            const lastVIewedCity = cities.find((tarsillaCity) => tarsillaCity.id === user?.lastViewedCityId);
            if (lastVIewedCity) {
                setSelected(lastVIewedCity.city.id);
            } else {
                setSelected(cities[0].city.id);
            }
        }
        // setLoading(false);
    }

    useEffect(() => {
        if (items.length === 0 && user && isFocused) {
            getCities(user.client as HttpClient);
        }
    }, [items, user, isFocused]);

    async function updateLastViewedCity(client: HttpClient, selected: string) {
        const lastViewedCity = items.find((tarsillaCity) => tarsillaCity.value === selected);

        if (lastViewedCity?.tarsillaCityId !== user?.lastViewedCityId) {
            await client.post('/user/last_viewed_city', { id: lastViewedCity?.tarsillaCityId });
        }
    }

    useEffect(() => {
        if (user && selected != null) {
            updateLastViewedCity(user.client as HttpClient, selected);
        }
    }, [user, selected]);

    return (
        <View style={{ height: '100%' }}>
            <Head
                title={title}
                items={items}
                selected={selected}
                setSelected={setSelected}
                search={search}
                setSearch={setSearch}
                materialPatrimonyType={materialPatrimonyType}
                setMaterialPatrimonyType={setMaterialPatrimonyType}
                materialPatrimonyMovableType={materialPatrimonyMovableType}
                setMaterialPatrimonyMovableType={setMaterialPatrimonyMovableType}
                materialPatrimonyImmovableType={materialPatrimonyImmovableType}
                setMaterialPatrimonyImmovableType={setMaterialPatrimonyImmovableType}
                materialPatrimonyLegalProtection={materialPatrimonyLegalProtection}
                setMaterialPatrimonyLegalProtection={setMaterialPatrimonyLegalProtection}
                materialPatrimonyAdministrativeInstance={materialPatrimonyAdministrativeInstance}
                setMaterialPatrimonyAdministrativeInstance={setMaterialPatrimonyAdministrativeInstance}
                materialPatrimonyAvailable={materialPatrimonyAvailable}
                setMaterialPatrimonyAvailable={setMaterialPatrimonyAvailable}
            />
            <Component
                selected={selected}
                search={debouncedSearch}
                materialPatrimonyType={materialPatrimonyType}
                materialPatrimonyMovableType={materialPatrimonyMovableType}
                materialPatrimonyImmovableType={materialPatrimonyImmovableType}
                materialPatrimonyLegalProtection={materialPatrimonyLegalProtection}
                materialPatrimonyAdministrativeInstance={materialPatrimonyAdministrativeInstance}
                materialPatrimonyAvailable={materialPatrimonyAvailable}
            />
        </View>
    );
}

function InventoryFilter({ Component, title }: InventoryFilterProps): FunctionComponent {
    return () => <Filter Component={Component} title={title} />;
}

export default InventoryFilter;
