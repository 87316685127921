import { branco, magenta, rosa } from './cores';
import fonts from './fonts';
import { Theme } from '../domain';

const LightTheme: Theme = {
    mode: 'light',
    minimumWidth: 320,
    background: branco,
    navigation: {
        background: branco,
        border: rosa,
        card: branco,
        notification: magenta,
        primary: magenta,
        text: magenta,
        active: magenta,
        inactive: rosa,
    },
    button: {
        fontFamily: fonts.medium,
        fontSize: 12,
        textColor: magenta,
        borderColor: magenta,
        backgroundColor: rosa,
        pressed: {
            textColor: rosa,
            borderColor: rosa,
            backgroundColor: magenta,
        },
    },
    text: {
        fontFamily: fonts.medium,
        fontSize: 12,
        textColor: magenta,
    },
    topMenu: {
        height: 72,
        backgroundColor: '#FFF',
        textColor: '#442772',
        textFontFamily: fonts.bold,
        textFontSize: 16,
        textLineHeight: 16,
        distance: 12,
        buttonColor: '#442772',
        buttonTextColor: '#FFF',
        buttonBorderRadius: 50,
        buttonHeight: 36,
        buttonPadding: 12,
    },
    sideMenu: {
        width: 192,
        padding: 16,
        iconColor: '#442772',
        iconSize: 36,
        backgroundColor: '#442772',
        textColor: '#FFF',
        textFontFamily: fonts.bold,
        textFontSize: 16,
        textLineHeight: 16,
        distance: 8,
        buttonColor: '#FFF',
        buttonTextColor: '#442772',
        buttonBorderRadius: 50,
        buttonHeight: 36,
        buttonPadding: 12,
    },
    anonymousHome: {
        textFontFamily: fonts.light,
        textHighlightedFontFamily: fonts.semiBold,
        textFontSize: 48,
        textLineHeight: 60,
        contactTextFontFamily: fonts.semiBold,
        contactTextFontSize: 40,
        contactTextLineHeight: 40,
        contactTextColor: '#442772',
        contactButtonFontFamily: fonts.bold,
        contactButtonFontSize: 16,
        contactButtonLineHeight: 20,
        contactButtonColor: '#BBD479',
        contactButtonTextColor: '#442772',
        contactButtonBorderRadius: 64,
        contactButtonPadding: 12,
    },
    contact: {
        textFontFamily: fonts.bold,
        textFontSize: 48,
        textLineHeight: 60,
        inputFontFamily: fonts.bold,
        inputFontSize: 16,
        inputLineHeight: 20,
        buttonFontFamily: fonts.bold,
        buttonFontSize: 16,
        buttonLineHeight: 20,
    },
    kanban: {
        titleColor: '#442772',
        titleFontFamily: fonts.bold,
        titleFontSize: 20,
        titleLineHeight: 22,
        titleBackgroundColor: '#BBD479',
        titleAllowedToReceiveBorderColor: '#442772',
        titleReceivingBackgroundColor: '#BBD479',
        backgroundColor: '#442772',
        allowedToReceiveBorderColor: '#BBD479',
        receivingBackgroundColor: '#442772',
        cardBackgroundColor: '#FFFFFF',
        cardDraggingBackgroundColor: '#96CEED',
        cardDraggingBorderColor: '#442772',
    },
    maintenanceKanbanCard: {
        codeFontFamily: fonts.bold,
        codeFontSize: 16,
        codeLineHeight: 18,
        codeColor: '#442772',
        titleFontFamily: fonts.medium,
        titleFontSize: 12,
        titleLineHeight: 14,
        titleColor: '#442772',
        iconColor: '#442772',
        iconSize: 32,
        patrimonyFontFamily: fonts.medium,
        patrimonyFontSize: 12,
        patrimonyLineHeight: 14,
        patrimonyColor: '#442772',
    },
    activityIndicator: {
        size: 'large',
        color: '#FF9300',
        type: 'any',
    },
    populationPatrimonyCard: {
        MATERIAL: {
            backgroundColor: '#442772',
        },
        INTANGIBLE: {
            backgroundColor: '#BBD479',
        },
    },
    inventoryFilter: {
        backgroundColor: '#442772',
        title: {
            textColor: '#FFFFFF',
            fontSize: 40,
            lineHeight: 40,
            fontFamily: fonts.bold,
        },
        primaryDropdown: {
            backgroundColor: '#FFFFFF',
            textColor: '#FFFFFF',
        },
        secondaryDropdown: {
            backgroundColor: '#442772',
            textColor: '#FFFFFF',
        },
        filterButton: {
            backgroundColor: '#BBD479',
            textColor: '#442772',
            fontSize: 16,
            lineHeight: 16,
            fontFamily: fonts.bold,
        },
        search: {
            borderColor: '#FFFFFF',
            backgroundColor: '#442772',
            textColor: '#FFFFFF',
            fontSize: 16,
            lineHeight: 16,
            fontFamily: fonts.bold,
        },
    },
    inventory: {
        notFound: {
            textColor: '#442772',
            fontSize: 24,
            lineHeight: 24,
            fontFamily: fonts.bold,
        },
    },
};

export default LightTheme;
