enum MovablePatrimonySculpturalTypeEnum {
    BUST = 'BUST',
    HEAD = 'HEAD',
    OBELISCK = 'OBELISCK',
    RELIEF = 'RELIEF',
    SCULPTURAL_BLADE = 'SCULPTURAL_BLADE',
    SCULPTURAL_GROUP = 'SCULPTURAL_GROUP',
    SCULPTURE = 'SCULPTURE',
    STATUE = 'STATUE',
}

export default MovablePatrimonySculpturalTypeEnum;
