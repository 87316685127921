import { Children, ReactNode } from 'react';

import { Entypo, FontAwesome5 } from '@expo/vector-icons';
import { useNavigation, NavigationProp } from '@react-navigation/native';
import { FormattedMessage, useIntl } from 'react-intl';
import { Image, Linking, Platform, Text, TouchableOpacity, View, useWindowDimensions } from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import { useTheme } from '@contexts';
import { RootNavigatorParamList } from '@navigation';

function AboutUsBig() {
    const { height, width } = useWindowDimensions();
    const { theme } = useTheme();
    const { navigate } = useNavigation<NavigationProp<RootNavigatorParamList>>();
    const { formatMessage } = useIntl();
    const { top, bottom } = useSafeAreaInsets();

    const textWidth = 545;

    return (
        <View>
            <View style={{ flexDirection: 'row', height: height - theme.topMenu.height - top - bottom, minHeight: theme.anonymousHome.textLineHeight * 3 + 32 }}>
                <Image
                    source={require('@assets/about_us/1.jpg')}
                    style={{ width: width - textWidth, height: height - theme.topMenu.height - top - bottom, minHeight: theme.anonymousHome.textLineHeight * 3 + 32 }}
                />
                <View style={{ backgroundColor: '#BBD479', width: textWidth, padding: 32, justifyContent: 'center' }}>
                    <View style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
                        <FormattedMessage
                            id="pages.home.slide1"
                            values={{
                                Text: (...chunks: ReactNode[]) => (
                                    <Text
                                        style={{
                                            flexShrink: 1,
                                            lineHeight: theme.anonymousHome.textLineHeight,
                                            fontSize: theme.anonymousHome.textFontSize,
                                            fontFamily: theme.anonymousHome.textFontFamily,
                                            color: '#FFF',
                                        }}
                                    >
                                        {Children.toArray(chunks)}
                                    </Text>
                                ),
                                TextHighlighted: (...chunks: ReactNode[]) => (
                                    <Text
                                        style={{
                                            flexShrink: 1,
                                            lineHeight: theme.anonymousHome.textLineHeight,
                                            fontSize: theme.anonymousHome.textFontSize,
                                            fontFamily: theme.anonymousHome.textHighlightedFontFamily,
                                            color: '#FFF',
                                        }}
                                    >
                                        {Children.toArray(chunks)}
                                    </Text>
                                ),
                            }}
                        />
                    </View>
                </View>
            </View>
            <View style={{ flexDirection: 'row', height: height - theme.topMenu.height - top - bottom, minHeight: theme.anonymousHome.textLineHeight * 3 + 32 }}>
                <View style={{ backgroundColor: '#442772', width: textWidth, padding: 32, justifyContent: 'center' }}>
                    <View style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
                        <FormattedMessage
                            id="pages.home.slide2"
                            values={{
                                Text: (...chunks: ReactNode[]) => (
                                    <Text
                                        style={{
                                            flexShrink: 1,
                                            lineHeight: theme.anonymousHome.textLineHeight,
                                            fontSize: theme.anonymousHome.textFontSize,
                                            fontFamily: theme.anonymousHome.textFontFamily,
                                            color: '#FFF',
                                        }}
                                    >
                                        {Children.toArray(chunks)}
                                    </Text>
                                ),
                                TextHighlighted: (...chunks: ReactNode[]) => (
                                    <Text
                                        style={{
                                            flexShrink: 1,
                                            lineHeight: theme.anonymousHome.textLineHeight,
                                            fontSize: theme.anonymousHome.textFontSize,
                                            fontFamily: theme.anonymousHome.textHighlightedFontFamily,
                                            color: '#FFF',
                                        }}
                                    >
                                        {Children.toArray(chunks)}
                                    </Text>
                                ),
                            }}
                        />
                    </View>
                </View>
                <Image
                    source={require('@assets/about_us/2.jpg')}
                    style={{ width: width - textWidth, height: height - theme.topMenu.height - top - bottom, minHeight: theme.anonymousHome.textLineHeight * 3 + 32 }}
                />
            </View>
            <View style={{ flexDirection: 'row', height: height - theme.topMenu.height - top - bottom, minHeight: theme.anonymousHome.textLineHeight * 3 + 32 }}>
                <Image
                    source={require('@assets/about_us/3.jpg')}
                    style={{ width: width - textWidth, height: height - theme.topMenu.height - top - bottom, minHeight: theme.anonymousHome.textLineHeight * 3 + 32 }}
                />
                <View style={{ backgroundColor: '#EA6424', width: textWidth, padding: 32, justifyContent: 'center' }}>
                    <View style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
                        <FormattedMessage
                            id="pages.home.slide3"
                            values={{
                                Text: (...chunks: ReactNode[]) => (
                                    <Text
                                        style={{
                                            flexShrink: 1,
                                            lineHeight: theme.anonymousHome.textLineHeight,
                                            fontSize: theme.anonymousHome.textFontSize,
                                            fontFamily: theme.anonymousHome.textFontFamily,
                                            color: '#FFF',
                                        }}
                                    >
                                        {Children.toArray(chunks)}
                                    </Text>
                                ),
                                TextHighlighted: (...chunks: ReactNode[]) => (
                                    <Text
                                        style={{
                                            flexShrink: 1,
                                            lineHeight: theme.anonymousHome.textLineHeight,
                                            fontSize: theme.anonymousHome.textFontSize,
                                            fontFamily: theme.anonymousHome.textHighlightedFontFamily,
                                            color: '#FFF',
                                        }}
                                    >
                                        {Children.toArray(chunks)}
                                    </Text>
                                ),
                            }}
                        />
                    </View>
                </View>
            </View>
            <View style={{ backgroundColor: '#BBD479', padding: 32, flexDirection: 'column', alignItems: 'center', height: 314, minHeight: 314 }}>
                <Text
                    style={{
                        textAlign: 'center',
                        lineHeight: theme.anonymousHome.textLineHeight,
                        fontSize: theme.anonymousHome.textFontSize,
                        fontFamily: theme.anonymousHome.textHighlightedFontFamily,
                        color: '#442772',
                        textDecorationLine: 'underline',
                        textDecorationColor: '#442772',
                        textDecorationStyle: 'solid',
                        ...Platform.select({
                            web: {
                                textUnderlineOffset: 12,
                            },
                        }),
                        marginBottom: 32,
                    }}
                >
                    {formatMessage({ id: 'pages.home.slide4' })}
                </Text>
                <View style={{ flexDirection: 'row', alignItems: 'center', marginBottom: 24 }}>
                    <Image source={require('@assets/logos/partners/ideiaz.png')} style={{ width: 157, height: 65 }} />
                    <Image source={require('@assets/logos/partners/deepcamp.png')} style={{ width: 173, height: 74 }} />
                </View>
                <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                    <Image source={require('@assets/logos/partners/growplus.png')} style={{ width: 209, height: 37, marginRight: 12 }} />
                    <Image source={require('@assets/logos/partners/sebraeforstartups.png')} style={{ width: 155, height: 47, marginRight: 12 }} />
                    <Image source={require('@assets/logos/partners/ipt.png')} style={{ width: 140, height: 60, marginRight: 12 }} />
                    <Image source={require('@assets/logos/partners/iptopenexperience.png')} style={{ width: 152, height: 46 }} />
                </View>
            </View>
            <View style={{ backgroundColor: '#F5F1EE', padding: 32, flexDirection: 'row', justifyContent: 'space-between', height: 160, minHeight: 160 }}>
                <View
                    style={{
                        backgroundColor: '#F5F1EE',
                        flexDirection: width < 798 ? 'column' : 'row',
                        justifyContent: width < 798 ? 'center' : 'space-between',
                        alignItems: width < 798 ? 'center' : 'flex-end',
                    }}
                >
                    <TouchableOpacity
                        onPress={() => {
                            Linking.openURL('https://www.instagram.com/tarsilla.app');
                        }}
                        style={{ width: 40, height: 40, marginRight: width < 798 ? 0 : 8, marginBottom: width < 798 ? 8 : 0 }}
                    >
                        <Entypo name="instagram-with-circle" size={40} color="#442772" />
                    </TouchableOpacity>
                    <TouchableOpacity
                        onPress={() => {
                            Linking.openURL('https://www.tiktok.com/@tarsilla.app');
                        }}
                        style={{
                            backgroundColor: '#442772',
                            borderRadius: 100,
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: 40,
                            height: 40,
                            marginRight: width < 798 ? 0 : 8,
                            marginBottom: width < 798 ? 8 : -1,
                        }}
                    >
                        <FontAwesome5 name="tiktok" size={20} color="#FFF" />
                    </TouchableOpacity>
                    <TouchableOpacity
                        onPress={() => {
                            Linking.openURL('https://www.linkedin.com/mwlite/company/tarsilla');
                        }}
                        style={{ width: 40, height: 40 }}
                    >
                        <Entypo name="linkedin-with-circle" size={40} color="#442772" />
                    </TouchableOpacity>
                </View>
                <View style={{ backgroundColor: '#F5F1EE', flexDirection: 'column', justifyContent: 'space-between' }}>
                    <View style={{ flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center', paddingBottom: 16 }}>
                        <Text
                            style={{
                                lineHeight: theme.anonymousHome.contactTextLineHeight,
                                fontSize: theme.anonymousHome.contactTextFontSize,
                                fontFamily: theme.anonymousHome.contactTextFontFamily,
                                color: theme.anonymousHome.contactTextColor,
                            }}
                        >
                            {formatMessage({ id: 'pages.home.slide5' })}
                        </Text>
                    </View>
                    <TouchableOpacity onPress={() => navigate('SignedOutNavigator', { screen: 'Contact' })}>
                        <Text
                            style={{
                                fontSize: theme.anonymousHome.contactButtonFontSize,
                                lineHeight: theme.anonymousHome.contactButtonLineHeight,
                                fontFamily: theme.anonymousHome.contactButtonFontFamily,
                                color: theme.anonymousHome.contactButtonTextColor,
                                textAlign: 'center',
                                backgroundColor: theme.anonymousHome.contactButtonColor,
                                borderRadius: theme.anonymousHome.contactButtonBorderRadius,
                                alignSelf: 'flex-start',
                                padding: theme.anonymousHome.contactButtonPadding,
                            }}
                        >
                            {formatMessage({ id: 'pages.home.contactButton' })}
                        </Text>
                    </TouchableOpacity>
                </View>
            </View>
        </View>
    );
}

function AboutUsSmall() {
    const { height, width } = useWindowDimensions();
    const { theme } = useTheme();
    const { navigate } = useNavigation<NavigationProp<RootNavigatorParamList>>();
    const { formatMessage } = useIntl();
    const { top, bottom } = useSafeAreaInsets();

    return (
        <View>
            <View>
                <Image source={require('@assets/about_us/1.jpg')} style={{ width, height: (height - theme.topMenu.height - top - bottom) / 2, minWidth: theme.minimumWidth }} />
                <View style={{ backgroundColor: '#BBD479', width, padding: 24, justifyContent: 'center', alignItems: 'center', minWidth: theme.minimumWidth }}>
                    <View style={{ flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center' }}>
                        <FormattedMessage
                            id="pages.home.slide1"
                            values={{
                                Text: (...chunks: ReactNode[]) => (
                                    <Text
                                        style={{
                                            flexShrink: 1,
                                            lineHeight: theme.anonymousHome.textLineHeight,
                                            fontSize: theme.anonymousHome.textFontSize,
                                            fontFamily: theme.anonymousHome.textFontFamily,
                                            color: '#FFF',
                                        }}
                                    >
                                        {Children.toArray(chunks)}
                                    </Text>
                                ),
                                TextHighlighted: (...chunks: ReactNode[]) => (
                                    <Text
                                        style={{
                                            flexShrink: 1,
                                            lineHeight: theme.anonymousHome.textLineHeight,
                                            fontSize: theme.anonymousHome.textFontSize,
                                            fontFamily: theme.anonymousHome.textHighlightedFontFamily,
                                            color: '#FFF',
                                        }}
                                    >
                                        {Children.toArray(chunks)}
                                    </Text>
                                ),
                            }}
                        />
                    </View>
                </View>
            </View>
            <View>
                <Image source={require('@assets/about_us/2.jpg')} style={{ width, height: (height - theme.topMenu.height - top - bottom) / 2, minWidth: theme.minimumWidth }} />
                <View style={{ backgroundColor: '#442772', width, padding: 24, justifyContent: 'center', alignItems: 'center', minWidth: theme.minimumWidth }}>
                    <View style={{ flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center' }}>
                        <FormattedMessage
                            id="pages.home.slide2"
                            values={{
                                Text: (...chunks: ReactNode[]) => (
                                    <Text
                                        style={{
                                            flexShrink: 1,
                                            lineHeight: theme.anonymousHome.textLineHeight,
                                            fontSize: theme.anonymousHome.textFontSize,
                                            fontFamily: theme.anonymousHome.textFontFamily,
                                            color: '#FFF',
                                        }}
                                    >
                                        {Children.toArray(chunks)}
                                    </Text>
                                ),
                                TextHighlighted: (...chunks: ReactNode[]) => (
                                    <Text
                                        style={{
                                            flexShrink: 1,
                                            lineHeight: theme.anonymousHome.textLineHeight,
                                            fontSize: theme.anonymousHome.textFontSize,
                                            fontFamily: theme.anonymousHome.textHighlightedFontFamily,
                                            color: '#FFF',
                                        }}
                                    >
                                        {Children.toArray(chunks)}
                                    </Text>
                                ),
                            }}
                        />
                    </View>
                </View>
            </View>
            <View>
                <Image source={require('@assets/about_us/3.jpg')} style={{ width, height: (height - theme.topMenu.height - top - bottom) / 2, minWidth: theme.minimumWidth }} />
                <View style={{ backgroundColor: '#EA6424', width, padding: 24, justifyContent: 'center', alignItems: 'center', minWidth: theme.minimumWidth }}>
                    <View style={{ flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center' }}>
                        <FormattedMessage
                            id="pages.home.slide3"
                            values={{
                                Text: (...chunks: ReactNode[]) => (
                                    <Text
                                        style={{
                                            flexShrink: 1,
                                            lineHeight: theme.anonymousHome.textLineHeight,
                                            fontSize: theme.anonymousHome.textFontSize,
                                            fontFamily: theme.anonymousHome.textFontFamily,
                                            color: '#FFF',
                                        }}
                                    >
                                        {Children.toArray(chunks)}
                                    </Text>
                                ),
                                TextHighlighted: (...chunks: ReactNode[]) => (
                                    <Text
                                        style={{
                                            flexShrink: 1,
                                            lineHeight: theme.anonymousHome.textLineHeight,
                                            fontSize: theme.anonymousHome.textFontSize,
                                            fontFamily: theme.anonymousHome.textHighlightedFontFamily,
                                            color: '#FFF',
                                        }}
                                    >
                                        {Children.toArray(chunks)}
                                    </Text>
                                ),
                            }}
                        />
                    </View>
                </View>
            </View>
            <View
                style={{
                    backgroundColor: '#BBD479',
                    padding: 32,
                    flexDirection: 'column',
                    alignItems: 'center',
                    height: width > 500 ? 385 : width > 420 ? 445 : 665,
                    minHeight: width > 500 ? 385 : width > 420 ? 445 : 665,
                }}
            >
                <View style={{ flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center', minWidth: theme.minimumWidth, marginBottom: 32 }}>
                    <Text
                        style={{
                            textAlign: 'center',
                            lineHeight: theme.anonymousHome.textLineHeight,
                            fontSize: theme.anonymousHome.textFontSize,
                            fontFamily: theme.anonymousHome.textHighlightedFontFamily,
                            color: '#442772',
                            textDecorationLine: 'underline',
                            textDecorationColor: '#442772',
                            textDecorationStyle: 'solid',
                            ...Platform.select({
                                web: {
                                    textUnderlineOffset: 12,
                                },
                            }),
                        }}
                    >
                        {formatMessage({ id: 'pages.home.slide4' })}
                    </Text>
                </View>
                {width <= 720 && width > 420 && (
                    <View style={{ backgroundColor: '#BBD479', flexDirection: 'column', alignItems: 'center' }}>
                        <View style={{ flexDirection: 'row', alignItems: 'center', marginBottom: 24 }}>
                            <Image source={require('@assets/logos/partners/ideiaz.png')} style={{ width: 157, height: 65 }} />
                            <Image source={require('@assets/logos/partners/deepcamp.png')} style={{ width: 173, height: 74 }} />
                        </View>
                        <View style={{ flexDirection: 'row', alignItems: 'center', marginBottom: 24 }}>
                            <Image source={require('@assets/logos/partners/growplus.png')} style={{ width: 209, height: 37, marginRight: 12 }} />
                            <Image source={require('@assets/logos/partners/sebraeforstartups.png')} style={{ width: 155, height: 47 }} />
                        </View>
                        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                            <Image source={require('@assets/logos/partners/ipt.png')} style={{ width: 140, height: 60, marginRight: 12 }} />
                            <Image source={require('@assets/logos/partners/iptopenexperience.png')} style={{ width: 152, height: 46 }} />
                        </View>
                    </View>
                )}
                {width <= 420 && (
                    <View style={{ backgroundColor: '#BBD479', flexDirection: 'column', alignItems: 'center' }}>
                        <Image source={require('@assets/logos/partners/ideiaz.png')} style={{ width: 157, height: 65, marginBottom: 24 }} />
                        <Image source={require('@assets/logos/partners/deepcamp.png')} style={{ width: 173, height: 74, marginBottom: 24 }} />
                        <Image source={require('@assets/logos/partners/growplus.png')} style={{ width: 209, height: 37, marginBottom: 24 }} />
                        <Image source={require('@assets/logos/partners/sebraeforstartups.png')} style={{ width: 155, height: 47, marginBottom: 24 }} />
                        <Image source={require('@assets/logos/partners/ipt.png')} style={{ width: 140, height: 60, marginBottom: 24 }} />
                        <Image source={require('@assets/logos/partners/iptopenexperience.png')} style={{ width: 152, height: 46 }} />
                    </View>
                )}
            </View>

            <View
                style={{
                    backgroundColor: '#F5F1EE',
                    padding: width < 365 ? 16 : 32,
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    height: width >= 690 ? 160 : undefined,
                    minHeight: 160,
                    minWidth: theme.minimumWidth,
                }}
            >
                <View
                    style={{
                        backgroundColor: '#F5F1EE',
                        flexDirection: width < 798 ? 'column' : 'row',
                        justifyContent: width < 798 ? 'center' : 'space-between',
                        alignItems: width < 798 ? 'center' : 'flex-end',
                    }}
                >
                    <TouchableOpacity
                        onPress={() => {
                            Linking.openURL('https://www.instagram.com/tarsilla.app');
                        }}
                        style={{ width: 40, height: 40, marginRight: width < 798 ? 0 : 8, marginBottom: width < 798 ? 8 : 0 }}
                    >
                        <Entypo name="instagram-with-circle" size={40} color="#442772" />
                    </TouchableOpacity>
                    <TouchableOpacity
                        onPress={() => {
                            Linking.openURL('https://www.tiktok.com/@tarsilla.app');
                        }}
                        style={{
                            backgroundColor: '#442772',
                            borderRadius: 100,
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: 40,
                            height: 40,
                            marginRight: width < 798 ? 0 : 8,
                            marginBottom: width < 798 ? 8 : -1,
                        }}
                    >
                        <FontAwesome5 name="tiktok" size={20} color="#FFF" />
                    </TouchableOpacity>
                    <TouchableOpacity
                        onPress={() => {
                            Linking.openURL('https://www.linkedin.com/mwlite/company/tarsilla');
                        }}
                        style={{ width: 40, height: 40 }}
                    >
                        <Entypo name="linkedin-with-circle" size={40} color="#442772" />
                    </TouchableOpacity>
                </View>
                <View style={{ flex: 1, backgroundColor: '#F5F1EE', flexDirection: 'column', justifyContent: 'space-between', paddingLeft: width < 365 ? 8 : 16 }}>
                    <View
                        style={{
                            flexDirection: 'row',
                            flexWrap: 'wrap',
                            justifyContent: 'center',
                            alignSelf: 'center',
                            paddingBottom: 16,
                            minWidth: theme.minimumWidth - 120,
                        }}
                    >
                        <Text
                            style={{
                                lineHeight: theme.anonymousHome.contactTextLineHeight,
                                fontSize: theme.anonymousHome.contactTextFontSize,
                                fontFamily: theme.anonymousHome.contactTextFontFamily,
                                color: theme.anonymousHome.contactTextColor,
                                textAlign: 'center',
                            }}
                        >
                            {formatMessage({ id: 'pages.home.slide5' })}
                        </Text>
                    </View>
                    <TouchableOpacity onPress={() => navigate('SignedOutNavigator', { screen: 'Contact' })}>
                        <Text
                            style={{
                                fontSize: theme.anonymousHome.contactButtonFontSize,
                                lineHeight: theme.anonymousHome.contactButtonLineHeight,
                                fontFamily: theme.anonymousHome.contactButtonFontFamily,
                                color: theme.anonymousHome.contactButtonTextColor,
                                textAlign: 'center',
                                backgroundColor: theme.anonymousHome.contactButtonColor,
                                borderRadius: theme.anonymousHome.contactButtonBorderRadius,
                                alignSelf: 'center',
                                padding: theme.anonymousHome.contactButtonPadding,
                            }}
                        >
                            {formatMessage({ id: 'pages.home.contactButton' })}
                        </Text>
                    </TouchableOpacity>
                </View>
            </View>
        </View>
    );
}

function AboutUs(): JSX.Element {
    const { width } = useWindowDimensions();
    return <ScrollView>{width >= 720 ? <AboutUsBig /> : <AboutUsSmall />}</ScrollView>;
}

export default AboutUs;
