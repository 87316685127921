enum MaterialTypeEnum {
    BRONZE = 'BRONZE',
    GRANITE = 'GRANITE',
    CAST_IRON = 'CAST_IRON',
    MORTAR = 'MORTAR',
    CONCRETE = 'CONCRETE',
    PORTUGUESE_STONE = 'PORTUGUESE_STONE',
    PAINTED_STEEL = 'PAINTED_STEEL',
    CORTEN_STEEL = 'CORTEN_STEEL',
    MARBLE = 'MARBLE',
    LEAD = 'LEAD',
    CARRARA_MARBLE = 'CARRARA_MARBLE',
    GNEISS = 'GNEISS',
    WHITE_MARBLE = 'WHITE_MARBLE',
    MASONRY = 'MASONRY',
    PETROPOLIS_GRANITE = 'PETROPOLIS_GRANITE',
}

export default MaterialTypeEnum;
