import { createContext, PropsWithChildren, useContext as useReactContext, useEffect, useState } from 'react';

import { exchangeCodeAsync, makeRedirectUri, useAuthRequest, useAutoDiscovery, revokeAsync, TokenResponse, TokenResponseConfig, refreshAsync, DiscoveryDocument } from 'expo-auth-session';
import { default as Constants } from 'expo-constants';
import { maybeCompleteAuthSession, openAuthSessionAsync } from 'expo-web-browser';

import { User, UserInstitution } from '@domain';
import { TarsillaUserTypEnum } from '@enum';
import { useErrorToast } from '@hooks';

import { httpClient } from './http_client';
import { useLoading } from '../loading';
import { useSession } from '../session';

const redirectUri = makeRedirectUri();

console.log('redirectUri', redirectUri); // <-- you will need to add this to your auth0 callbacks / logout configs

maybeCompleteAuthSession();

type ContextProps = {
    user: User | null;
    logIn: () => Promise<void>;
    logOut: () => Promise<void>;
};

const Context = createContext<ContextProps | null>(null);

type QueryProps = {
    lastViewedCityId?: string;
    lastViewedInstitutionId?: string;
    institutions?: {
        id: string;
        type: TarsillaUserTypEnum;
        name: string;
    }[];
};

async function buildUser(token: string, showErrorMessage: (message: string) => void, refreshToken: () => Promise<void>): Promise<User | null> {
    const client = httpClient(token, showErrorMessage, refreshToken);
    const loggedUser = await client.get<QueryProps>('/user');
    if (loggedUser) {
        let activeInstitution: UserInstitution | undefined;
        if (loggedUser.institutions && loggedUser.institutions.length > 0) {
            const institution = loggedUser.institutions.find((i) => i.id === loggedUser.lastViewedInstitutionId) ?? loggedUser.institutions[0];
            activeInstitution = {
                id: institution.id,
                name: institution.name,
                role: institution.type,
            };
        }

        return {
            client,
            lastViewedCityId: loggedUser.lastViewedCityId,
            activeInstitution,
            institutions: loggedUser.institutions?.map((institution) => {
                return {
                    id: institution.id,
                    name: institution.name,
                    role: institution.type,
                };
            }),
        };
    }

    return null;
}

function Provider({ children }: PropsWithChildren): JSX.Element {
    const { show } = useErrorToast();
    const discovery = useAutoDiscovery(Constants.expoConfig?.extra?.auth0Namespace);

    const [request, response, promptAsync] = useAuthRequest(
        {
            usePKCE: true,
            redirectUri,
            clientId: Constants.expoConfig?.extra?.auth0ClientId,
            responseType: 'code',
            scopes: ['openid', 'profile', 'email', 'offline_access'],
            extraParams: {
                audience: Constants.expoConfig?.extra?.auth0Audience,
            },
        },
        discovery,
    );

    const { getToken: getAccessToken, setToken: setAccessToken } = useSession();
    const { setInitialLoading } = useLoading();

    const [user, setUser] = useState<User | null>(null);

    useEffect(() => {
        if (discovery?.tokenEndpoint) {
            (async () => {
                const tokenString = await getAccessToken();
                if (tokenString) {
                    const tokenConfig: TokenResponseConfig = JSON.parse(tokenString);
                    const tokenResponse = new TokenResponse(tokenConfig);

                    if (tokenResponse.shouldRefresh()) {
                        await refreshToken(discovery, tokenResponse, true);
                        setInitialLoading(false);
                    } else {
                        const user = await buildUser(tokenResponse.accessToken, show, () => refreshToken(discovery, tokenResponse));
                        setUser(user);
                        setInitialLoading(false);
                    }
                } else {
                    setInitialLoading(false);
                }
            })();
        }
    }, [discovery]);

    useEffect(() => {
        (async () => {
            if (discovery && request && request.codeVerifier && response && response.type === 'success') {
                await exchangeCodeAsync(
                    {
                        clientId: Constants.expoConfig?.extra?.auth0ClientId,
                        code: response.params.code,
                        redirectUri,
                        extraParams: {
                            code_verifier: request.codeVerifier,
                        },
                    },
                    discovery,
                ).then(async (token) => {
                    const tokenConfig = token.getRequestConfig();
                    const tokenResponse = new TokenResponse(tokenConfig);

                    await setAccessToken(JSON.stringify(tokenResponse));

                    const user = await buildUser(tokenResponse.accessToken, show, () => refreshToken(discovery, tokenResponse));

                    setUser(user);
                    setInitialLoading(false);
                });
            }
        })();
    }, [discovery, request, response]);

    async function logIn() {
        setInitialLoading(true);
        await promptAsync();
    }

    async function logOut() {
        setInitialLoading(true);
        const token = await getAccessToken();

        if (token != null && discovery?.revocationEndpoint != null) {
            await revokeAsync(
                {
                    token,
                    clientId: Constants.expoConfig?.extra?.auth0ClientId,
                    extraParams: {
                        audience: Constants.expoConfig?.extra?.auth0Audience,
                    },
                },
                discovery,
            );

            const logoutUrl = `${Constants.expoConfig?.extra?.auth0Namespace}/v2/logout?client_id=${Constants.expoConfig?.extra?.auth0ClientId}&returnTo=${redirectUri}`;
            await openAuthSessionAsync(logoutUrl, redirectUri);

            setUser(null);
            await setAccessToken(null);
        } else {
            const logoutUrl = `${Constants.expoConfig?.extra?.auth0Namespace}/v2/logout?client_id=${Constants.expoConfig?.extra?.auth0ClientId}&returnTo=${redirectUri}`;
            await openAuthSessionAsync(logoutUrl, redirectUri);

            setUser(null);
            await setAccessToken(null);
        }

        setInitialLoading(false);
    }

    async function refreshToken(discovery: DiscoveryDocument, tokenResponse: TokenResponse, shouldBuildUser?: boolean) {
        const refreshConfig = {
            clientId: Constants.expoConfig?.extra?.auth0ClientId,
            extraParams: {
                audience: Constants.expoConfig?.extra?.auth0Audience,
            },
            refreshToken: tokenResponse.refreshToken,
        };
        await refreshAsync(refreshConfig, discovery)
            .then(async (response) => {
                await setAccessToken(JSON.stringify(response));

                if (shouldBuildUser === true) {
                    const user = await buildUser(response.accessToken, show, () => refreshToken(discovery, response));
                    setUser(user);
                } else {
                    const client = httpClient(response.accessToken, show, () => refreshToken(discovery, response));
                    setUser({ ...user, client });
                }
            })
            .catch(async () => {
                await logOut();
            });
    }

    return <Context.Provider value={{ user, logIn, logOut }}>{children}</Context.Provider>;
}

export default Provider;

export function useContext(): ContextProps {
    const context = useReactContext(Context);
    if (!context) {
        throw new Error('error auth context');
    }
    return context;
}
