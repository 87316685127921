enum MovablePatrimonyTypeEnum {
    SCULPTURAL = 'SCULPTURAL',
    PICTORIAL = 'PICTORIAL',
    DOCUMENTARY = 'DOCUMENTARY',
    BIBLIOGRAPHIC = 'BIBLIOGRAPHIC',
    ARCHIVAL = 'ARCHIVAL',
    PHOTOGRAPHIC = 'PHOTOGRAPHIC',
}

export default MovablePatrimonyTypeEnum;
