import { useState } from 'react';

import axios from 'axios';
import { default as Constants } from 'expo-constants';
import { useIntl } from 'react-intl';
import { Text, TextInput, TouchableOpacity, View, useWindowDimensions } from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';

import { useLoading, useTheme } from '@contexts';
import { useErrorToast, useSuccessToast } from '@hooks';

function Contact(): JSX.Element {
    const { show: showSuccess } = useSuccessToast();
    const { show: showError } = useErrorToast();
    const { height, width } = useWindowDimensions();
    const { theme } = useTheme();
    const { formatMessage } = useIntl();
    const { setLoading } = useLoading();

    const [name, onChangeName] = useState('');
    const [email, onChangeEmail] = useState('');
    const [phone, onChangePhone] = useState('');
    const [message, onChangeMessage] = useState('');

    const layoutCut = 900;

    const sendEmail = async () => {
        setLoading(true);
        await axios
            .post(`${Constants.expoConfig?.extra?.sqlApiUrl}/mail/mailgun`, { name, email, phone, message: message.replace(/(?:\r\n|\r|\n)/g, '<br>') })
            .then(() => {
                showSuccess('pages.contact.success-message');
                onChangeName('');
                onChangeEmail('');
                onChangePhone('');
                onChangeMessage('');
            })
            .catch(() => showError('message.error.internal-server-error'));
        setLoading(false);
    };

    return (
        <ScrollView>
            <View style={{ flexDirection: width < layoutCut ? 'column' : 'row' }}>
                <View
                    style={{
                        backgroundColor: '#442772',
                        width: width < layoutCut ? width : width / 2,
                        flexDirection: 'row',
                        flexWrap: 'wrap',
                        padding: 64,
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: width < layoutCut ? undefined : height - theme.topMenu.height,
                        minHeight: width < 398 ? 428 : width < layoutCut ? 368 : 454,
                        minWidth: theme.minimumWidth,
                    }}
                >
                    <Text
                        style={{
                            color: '#BBD479',
                            fontSize: theme.contact.textFontSize,
                            lineHeight: theme.contact.textLineHeight,
                            fontFamily: theme.contact.textFontFamily,
                            textAlign: 'center',
                        }}
                    >
                        {formatMessage({ id: 'pages.contact.text' })}
                    </Text>
                </View>
                <View
                    style={{
                        width: width < layoutCut ? width : width / 2,
                        flexDirection: 'column',
                        flexWrap: 'wrap',
                        padding: 64,
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: width < layoutCut ? undefined : height - theme.topMenu.height,
                        minHeight: width < 437 ? 474 : 454,
                        borderTopWidth: 1,
                        borderTopColor: '#442772',
                    }}
                >
                    <TextInput
                        style={{
                            color: '#442772',
                            borderBottomWidth: 1,
                            borderBottomColor: '#442772',
                            fontSize: theme.contact.inputFontSize,
                            lineHeight: theme.contact.inputLineHeight,
                            fontFamily: theme.contact.inputFontFamily,
                            marginBottom: 24,
                            width: width < layoutCut ? width - 128 : width / 2 - 128,
                            minWidth: theme.minimumWidth - 128,
                        }}
                        onChangeText={onChangeName}
                        value={name}
                        placeholder={formatMessage({ id: 'pages.contact.name' })}
                    />
                    <TextInput
                        style={{
                            color: '#442772',
                            borderBottomWidth: 1,
                            borderBottomColor: '#442772',
                            fontSize: theme.contact.inputFontSize,
                            lineHeight: theme.contact.inputLineHeight,
                            fontFamily: theme.contact.inputFontFamily,
                            marginBottom: 24,
                            width: width < layoutCut ? width - 128 : width / 2 - 128,
                            minWidth: theme.minimumWidth - 128,
                        }}
                        onChangeText={onChangeEmail}
                        value={email}
                        placeholder={formatMessage({ id: 'pages.contact.email' })}
                    />
                    <TextInput
                        style={{
                            color: '#442772',
                            borderBottomWidth: 1,
                            borderBottomColor: '#442772',
                            fontSize: theme.contact.inputFontSize,
                            lineHeight: theme.contact.inputLineHeight,
                            fontFamily: theme.contact.inputFontFamily,
                            marginBottom: 24,
                            width: width < layoutCut ? width - 128 : width / 2 - 128,
                            minWidth: theme.minimumWidth - 128,
                        }}
                        onChangeText={onChangePhone}
                        value={phone}
                        placeholder={formatMessage({ id: 'pages.contact.phone' })}
                    />
                    <TextInput
                        style={{
                            color: '#442772',
                            borderBottomWidth: 1,
                            borderBottomColor: '#442772',
                            fontSize: theme.contact.inputFontSize,
                            lineHeight: theme.contact.inputLineHeight,
                            fontFamily: theme.contact.inputFontFamily,
                            marginBottom: 24,
                            width: width < layoutCut ? width - 128 : width / 2 - 128,
                            minWidth: theme.minimumWidth - 128,
                        }}
                        onChangeText={onChangeMessage}
                        value={message}
                        placeholder={formatMessage({ id: 'pages.contact.message' })}
                        multiline
                        numberOfLines={6}
                        maxLength={500}
                    />
                    <TouchableOpacity
                        onPress={sendEmail}
                        style={{
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                        disabled={!name || !email || !phone || !message}
                    >
                        <View
                            style={{
                                backgroundColor: '#442772',
                                borderRadius: 50,
                                flexDirection: 'row',
                                flexWrap: 'wrap',
                                justifyContent: 'center',
                                alignItems: 'center',
                                padding: 12,
                                minWidth: theme.minimumWidth - 128,
                            }}
                        >
                            <Text
                                style={{
                                    fontSize: theme.contact.buttonFontSize,
                                    lineHeight: theme.contact.buttonLineHeight,
                                    fontFamily: theme.contact.buttonFontFamily,
                                    color: '#FFF',
                                    textAlign: 'center',
                                }}
                            >
                                {formatMessage({ id: 'pages.contact.button' })}
                            </Text>
                        </View>
                    </TouchableOpacity>
                </View>
            </View>
        </ScrollView>
    );
}

export default Contact;
