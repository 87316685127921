import { HeaderStyleInterpolators, createStackNavigator } from '@react-navigation/stack';

import { AboutUs, Contact } from '@pages';

import SafeViewFunction from './SafeViewFunction';

const StackNavigator = createStackNavigator<SignedOutNavigatorParamsList>();

export type SignedOutNavigatorParamsList = {
    AboutUs: undefined;
    Contact: undefined;
};

function SignedOutNavigator(): JSX.Element {
    return (
        <StackNavigator.Navigator
            screenOptions={{
                headerStyleInterpolator: HeaderStyleInterpolators.forUIKit,
            }}
        >
            <StackNavigator.Screen name="AboutUs" component={SafeViewFunction({ Component: AboutUs })} options={{ headerShown: false }} />
            <StackNavigator.Screen name="Contact" component={SafeViewFunction({ Component: Contact })} options={{ headerShown: false }} />
        </StackNavigator.Navigator>
    );
}

export default SignedOutNavigator;
