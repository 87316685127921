/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useIntl } from 'react-intl';
import { Text, View } from 'react-native';

import { Image } from '@base_components';
import { useTheme } from '@contexts';
import { Author, Patrimony } from '@domain';
import { getYear } from '@utils';

import useStyles from './styles';

type PatrimonyProps = {
    patrimony: Patrimony;
};

function PatrimonyCard({ patrimony }: PatrimonyProps): JSX.Element {
    const { formatMessage } = useIntl();
    const { theme } = useTheme();
    const styles = useStyles();

    const name = patrimony.name;
    const inaugurationDate = patrimony.date;

    let authors: Author[] = [];
    if (patrimony.materialPatrimony?.authors) {
        authors = patrimony.materialPatrimony.authors;
    }

    let type = '';
    if (patrimony.materialPatrimony) {
        type = `pages.inventory.enum.material-patrimony-type.${patrimony.materialPatrimony.type}`;
    }

    return (
        <View
            style={{
                width: 136,
                flexGrow: 1,
                backgroundColor: theme.populationPatrimonyCard[patrimony.type!].backgroundColor,
            }}
        >
            <View
                style={{
                    backgroundColor: '#FFFFFF',
                    height: 140,
                    width: 136,
                }}
            >
                <View
                    style={{
                        backgroundColor: '#FFFFFF',
                        height: 136,
                        width: 136,
                        borderWidth: 1,
                        borderColor: theme.populationPatrimonyCard[patrimony.type!].backgroundColor,
                        marginBottom: 4,
                    }}
                >
                    <Image bytea={patrimony.patrimonyImage!.thumbnail} format={patrimony.patrimonyImage!.format} height={134} width={134} resizeMode="contain" />
                </View>
            </View>
            <View style={{ padding: 8 }}>
                <Text style={styles.dateText}>{`${name}, ${getYear(inaugurationDate)}`}</Text>
                <Text style={styles.text}>{authors?.map((author) => author.personName ?? author.companyName).join(', ') ?? 'Desconhecida'}</Text>
                <Text style={styles.text}>{formatMessage({ id: type })}</Text>
            </View>
        </View>
    );
}

export default PatrimonyCard;
