import { createContext, PropsWithChildren, useContext as useReactContext, useEffect, useState } from 'react';

import { useFonts, OpenSans_300Light, OpenSans_400Regular, OpenSans_500Medium, OpenSans_600SemiBold, OpenSans_700Bold, OpenSans_800ExtraBold } from '@expo-google-fonts/open-sans';
import { ActivityIndicator, View, useColorScheme } from 'react-native';

import { Theme } from './domain';
import { DarkTheme, LightTheme } from './themes';

type ContextProps = {
    theme: Theme;
};

const Context = createContext<ContextProps>({} as ContextProps);

function Loading({ size, color }: { size: number | 'small' | 'large' | undefined; color: string }): JSX.Element {
    return (
        <View
            style={[
                {
                    position: 'absolute',
                    left: 0,
                    right: 0,
                    top: 0,
                    bottom: 0,
                    opacity: 0.5,
                    backgroundColor: 'black',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                    height: '100%',
                    zIndex: 1,
                },
            ]}
        >
            <ActivityIndicator size={size} color={color} />
        </View>
    );
}

function Provider({ children }: PropsWithChildren): JSX.Element {
    const [fontsLoaded] = useFonts({
        OpenSans_300Light,
        OpenSans_400Regular,
        OpenSans_500Medium,
        OpenSans_600SemiBold,
        OpenSans_700Bold,
        OpenSans_800ExtraBold,
    });
    const colorScheme = useColorScheme();

    const [theme, setTheme] = useState<Theme>(colorScheme === 'dark' ? DarkTheme : LightTheme);

    useEffect(() => {
        if (colorScheme === 'dark' && theme.mode === 'light') {
            setTheme(DarkTheme);
        } else if (colorScheme !== 'dark' && theme.mode === 'dark') {
            setTheme(LightTheme);
        }
    }, [colorScheme]);

    if (!fontsLoaded) return <Loading size={LightTheme.activityIndicator.size} color={LightTheme.activityIndicator.color} />;

    return <Context.Provider value={{ theme: LightTheme }}>{children}</Context.Provider>;
}

export function useContext(): ContextProps {
    const context = useReactContext(Context);
    if (!context) {
        throw new Error('error theme context');
    }
    return context;
}

export default Provider;
