import { useEffect, useRef, useState } from 'react';

import { Wrapper } from '@googlemaps/react-wrapper';
import { default as Constants } from 'expo-constants';
import { flushSync } from 'react-dom';
import { createRoot } from 'react-dom/client';

type MapMarkerProps = {
    position: { latitude: string; longitude: string };
    color?: string;
    infoWindow?: () => Promise<JSX.Element | null>;
};

type MapWrapperProps = {
    markers?: MapMarkerProps[];
};

function MapWrapper({ markers }: MapWrapperProps): JSX.Element {
    return (
        <Wrapper apiKey={Constants.manifest?.extra?.googleMapsAPI}>
            <MyMapComponent markers={markers} />
        </Wrapper>
    );
}

function MyMapComponent({ markers }: { markers?: MapMarkerProps[] }) {
    const ref = useRef<HTMLDivElement>(null);
    const [map, setMap] = useState<google.maps.Map>();
    const [mapMarkers, setMapMarkers] = useState<google.maps.Marker[]>([]);

    const activeInfoWindow = useRef<google.maps.InfoWindow | null>(null);
    useEffect(() => {
        if (ref.current && !map) {
            setMap(
                new google.maps.Map(ref.current, {
                    mapTypeId: 'roadmap',
                    styles: [
                        {
                            featureType: 'poi',
                            elementType: 'all',
                            stylers: [
                                {
                                    visibility: 'off',
                                },
                            ],
                        },
                        {
                            featureType: 'transit',
                            elementType: 'all',
                            stylers: [
                                {
                                    visibility: 'off',
                                },
                            ],
                        },
                    ],
                }),
            );
        }

        if (map && markers) {
            mapMarkers.forEach((marker) => marker.setMap(null));

            const markersI: google.maps.Marker[] = [];
            const bounds = new google.maps.LatLngBounds();

            markers.forEach((marker, index) => {
                let infowindow: google.maps.InfoWindow | null = null;
                const gMarker = new google.maps.Marker({
                    position: {
                        lat: parseFloat(marker.position.latitude),
                        lng: parseFloat(marker.position.longitude),
                    },
                    icon: {
                        path: 'M 0,0 C -2,-20 -10,-22 -10,-30 A 10,10 0 1,1 10,-30 C 10,-22 2,-20 0,0 z M -2,-30 a 2,2 0 1,1 4,0 2,2 0 1,1 -4,0',

                        fillOpacity: 1,
                        strokeColor: '#000',
                        strokeWeight: 2,
                        scale: 1,
                        fillColor: marker.color,
                    },
                    clickable: true,
                });

                gMarker.addListener('click', async () => {
                    if (activeInfoWindow.current != null) {
                        activeInfoWindow.current.close();
                        activeInfoWindow.current = null;
                    }
                    if (infowindow != null) {
                        activeInfoWindow.current = infowindow;
                        infowindow.open(map, gMarker);
                    } else if (marker.infoWindow != null) {
                        const content = await marker.infoWindow();

                        if (content != null) {
                            const div = document.createElement('div');
                            div.setAttribute('id', `marker${index}`);
                            const root = createRoot(div);
                            flushSync(() => {
                                root.render(content);
                            });

                            infowindow = new google.maps.InfoWindow({
                                content: div,
                            });
                            infowindow.addListener('domready', () => {
                                const content = document.getElementById(`marker${index}`);

                                if (content && content.parentElement && content.parentElement.nextElementSibling && content.parentElement.parentElement) {
                                    content.parentElement.parentElement.removeChild(content.parentElement.nextElementSibling);
                                    content.parentElement.parentElement.style.setProperty('padding', '8px', 'important');
                                    content.parentElement.style.setProperty('overflow', 'hidden', 'important');
                                }
                            });
                            activeInfoWindow.current = infowindow;
                            infowindow.open(map, gMarker);
                        }
                    }
                });

                gMarker.setMap(map);

                markersI.push(gMarker);
                bounds.extend({
                    lat: parseFloat(marker.position.latitude),
                    lng: parseFloat(marker.position.longitude),
                });
            });

            if (markersI.length > 0) {
                map.fitBounds(bounds);
            }

            map.addListener('click', () => {
                if (activeInfoWindow.current != null) {
                    activeInfoWindow.current.close();
                    activeInfoWindow.current = null;
                }
            });

            setMapMarkers(markersI);
        }
    }, [ref, map, markers]);

    return <div style={{ width: '100%', height: '100%' }} ref={ref} />;
}

export default MapWrapper;
