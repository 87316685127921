import enUS from './en-US.json';
import esES from './es-ES.json';
import ptBR from './pt-BR.json';

const locales = {
    'en-US': enUS,
    'es-ES': esES,
    'pt-BR': ptBR,
};

export default locales;
