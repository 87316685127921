import { useState } from 'react';

import { FontAwesome } from '@expo/vector-icons';
import { Text, View } from 'react-native';
import { Grid, Col } from 'react-native-easy-grid';

import { Kanban } from '@base_components';

import useStyles from './styles';

type MaintenanceBacklogItemType = {
    code: string;
    title: string;
    patrimony: string;
    assignees: string[];
    color: string;
};

function MaintenanceBacklogItem({ item }: { item: MaintenanceBacklogItemType }): JSX.Element {
    const styles = useStyles();

    return (
        <View>
            <View>
                <Grid>
                    <Col style={{ justifyContent: 'space-between' }}>
                        <View>
                            <Text style={styles.code}>{item.code}</Text>
                        </View>
                        <View>
                            <Text style={styles.title}>{item.title}</Text>
                        </View>
                    </Col>
                    <Col style={{ maxWidth: 32 }}>
                        <FontAwesome style={styles.icon} name={item.assignees.length > 1 ? 'users' : 'user'} />
                    </Col>
                </Grid>
            </View>
            <Text numberOfLines={1} ellipsizeMode="tail" style={[styles.patrimonyText, { backgroundColor: item.color }]}>
                {item.patrimony}
            </Text>
        </View>
    );
}

function MaintenanceBacklog(): JSX.Element {
    const [todo, setTodo] = useState<MaintenanceBacklogItemType[]>([
        { code: 'MA-1', title: 'Pintar a Fachada que está danificada', patrimony: 'Igreja São João', assignees: ['Aline Rayane'], color: '#BBD479' },
        { code: 'MA-2', title: 'Reconstruir a escadaria frontal', patrimony: 'Parque Lage', assignees: ['Stéfano Bassan'], color: '#BBD479' },
        { code: 'MA-3', title: 'Rafazer o Telhado', patrimony: 'Maternidade Matarazo em frente ao MASP', assignees: ['Aline Rayane', 'Stéfano Bassan'], color: '#BBD479' },
        { code: 'MA-3', title: 'Rafazer o Telhado', patrimony: 'Maternidade Matarazo em frente ao MASP', assignees: ['Aline Rayane', 'Stéfano Bassan'], color: '#BBD479' },
        { code: 'MA-3', title: 'Rafazer o Telhado', patrimony: 'Maternidade Matarazo em frente ao MASP', assignees: ['Aline Rayane', 'Stéfano Bassan'], color: '#BBD479' },
        { code: 'MA-3', title: 'Rafazer o Telhado', patrimony: 'Maternidade Matarazo em frente ao MASP', assignees: ['Aline Rayane', 'Stéfano Bassan'], color: '#BBD479' },
        { code: 'MA-3', title: 'Rafazer o Telhado', patrimony: 'Maternidade Matarazo em frente ao MASP', assignees: ['Aline Rayane', 'Stéfano Bassan'], color: '#BBD479' },
        { code: 'MA-3', title: 'Rafazer o Telhado', patrimony: 'Maternidade Matarazo em frente ao MASP', assignees: ['Aline Rayane', 'Stéfano Bassan'], color: '#BBD479' },
        { code: 'MA-3', title: 'Rafazer o Telhado', patrimony: 'Maternidade Matarazo em frente ao MASP', assignees: ['Aline Rayane', 'Stéfano Bassan'], color: '#BBD479' },
        { code: 'MA-3', title: 'Rafazer o Telhado', patrimony: 'Maternidade Matarazo em frente ao MASP', assignees: ['Aline Rayane', 'Stéfano Bassan'], color: '#BBD479' },
        { code: 'MA-3', title: 'Rafazer o Telhado', patrimony: 'Maternidade Matarazo em frente ao MASP', assignees: ['Aline Rayane', 'Stéfano Bassan'], color: '#BBD479' },
        { code: 'MA-3', title: 'Rafazer o Telhado', patrimony: 'Maternidade Matarazo em frente ao MASP', assignees: ['Aline Rayane', 'Stéfano Bassan'], color: '#BBD479' },
    ]);
    const [doing, setDoing] = useState<MaintenanceBacklogItemType[]>([]);

    const columns = [
        {
            id: 'doing',
            title: 'pages.maintenance_backlog.doing',
            renderItem: MaintenanceBacklogItem,
            items: doing,
            setItems: setDoing,
        },
        {
            id: 'todo',
            title: 'pages.maintenance_backlog.todo',
            renderItem: MaintenanceBacklogItem,
            items: todo,
            setItems: setTodo,
        },
    ];
    return <Kanban horizontal columns={columns} />;
}

export default MaintenanceBacklog;
