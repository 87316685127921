import { useEffect, useState } from 'react';

import { MaterialCommunityIcons } from '@expo/vector-icons';
import { useIsFocused } from '@react-navigation/native';
import { useIntl } from 'react-intl';
import { NativeScrollEvent, View, useWindowDimensions, Text } from 'react-native';
import { ScrollView, TouchableHighlight } from 'react-native-gesture-handler';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import { useAuth, useLoading, useTheme } from '@contexts';
import { HttpClient, Patrimony } from '@domain';
import { ImmovablePatrimonyTypeEnum, MaterialPatrimonyAdministrativeInstanceTypeEnum, MaterialPatrimonyLegalProtectionTypeEnum, MaterialPatrimonyTypeEnum, MovablePatrimonyTypeEnum } from '@enum';

import PatrimonyCard from './PatrimonyCard';
import { InventoryFilter } from '../../inventory_filter';
import { InventoryFilterComponentProps } from '../../inventory_filter/InventoryFilter';

export type RequestProps = {
    cityId: string | null;
    patrimonyName: string | null;
    materialPatrimonyType: MaterialPatrimonyTypeEnum | null;
    materialPatrimonyMovableType: MovablePatrimonyTypeEnum[] | null;
    materialPatrimonyImmovableType: ImmovablePatrimonyTypeEnum[] | null;
    materialPatrimonyLegalProtection: (MaterialPatrimonyLegalProtectionTypeEnum | 'NONE')[] | null;
    materialPatrimonyAdministrativeInstance: MaterialPatrimonyAdministrativeInstanceTypeEnum[] | null;
    materialPatrimonyAvailable: boolean[] | null;
    offset: number;
    limit: number;
};

function Inventory({
    selected,
    search,
    materialPatrimonyType,
    materialPatrimonyMovableType,
    materialPatrimonyImmovableType,
    materialPatrimonyLegalProtection,
    materialPatrimonyAdministrativeInstance,
    materialPatrimonyAvailable,
}: InventoryFilterComponentProps): JSX.Element | null {
    const isFocused = useIsFocused();
    const { formatMessage } = useIntl();
    const { bottom, top } = useSafeAreaInsets();
    const { width, height } = useWindowDimensions();
    const { user } = useAuth();
    const { setLoading } = useLoading();
    const { theme } = useTheme();

    const [patrimonies, setPatrimonies] = useState<Patrimony[] | null>(null);
    const [allPatrimoniesLoaded, setAllPatrimoniesLoaded] = useState<boolean>(false);

    const cardWidth = 136;
    const cardHeight = 250;
    const columns = Math.floor((width - 16) / (cardWidth + 8));
    const lines = Math.ceil((height * 1.5) / cardHeight);

    async function getPatrimonies(
        client: HttpClient,
        selected: string,
        search: string | null,
        materialPatrimonyType: MaterialPatrimonyTypeEnum | null,
        materialPatrimonyMovableType: MovablePatrimonyTypeEnum[] | null,
        materialPatrimonyImmovableType: ImmovablePatrimonyTypeEnum[] | null,
        materialPatrimonyLegalProtection: (MaterialPatrimonyLegalProtectionTypeEnum | 'NONE')[] | null,
        materialPatrimonyAdministrativeInstance: MaterialPatrimonyAdministrativeInstanceTypeEnum[] | null,
        materialPatrimonyAvailable: string[] | null,
        filter: boolean,
    ) {
        const offset = !filter && patrimonies ? patrimonies.length : 0;
        const limit = lines * columns;
        setLoading(true);
        const loadedPatrimonies = await client.post<RequestProps, Patrimony[]>('/population/inventory', {
            cityId: selected,
            patrimonyName: search,
            materialPatrimonyType,
            materialPatrimonyMovableType,
            materialPatrimonyImmovableType,
            materialPatrimonyLegalProtection,
            materialPatrimonyAdministrativeInstance,
            materialPatrimonyAvailable: materialPatrimonyAvailable?.map((item) => item !== 'true') ?? null,
            offset,
            limit,
        });
        if (loadedPatrimonies) {
            if (filter === true) {
                setPatrimonies(loadedPatrimonies);
                setAllPatrimoniesLoaded(false);
            } else {
                const newPatrimonies = patrimonies ? [...patrimonies, ...loadedPatrimonies] : loadedPatrimonies;
                setPatrimonies(newPatrimonies);
                setAllPatrimoniesLoaded(loadedPatrimonies.length < limit);
            }
        }
        setLoading(false);
    }

    useEffect(() => {
        if (user && selected && isFocused) {
            getPatrimonies(
                user.client as HttpClient,
                selected,
                search,
                materialPatrimonyType,
                materialPatrimonyMovableType,
                materialPatrimonyImmovableType,
                materialPatrimonyLegalProtection,
                materialPatrimonyAdministrativeInstance,
                materialPatrimonyAvailable,
                true,
            );
        }
    }, [
        user,
        selected,
        search,
        materialPatrimonyType,
        materialPatrimonyMovableType,
        materialPatrimonyImmovableType,
        materialPatrimonyLegalProtection,
        materialPatrimonyAdministrativeInstance,
        materialPatrimonyAvailable,
        isFocused,
    ]);

    function isCloseToBottom({ layoutMeasurement, contentOffset, contentSize }: NativeScrollEvent) {
        return layoutMeasurement.height + contentOffset.y >= contentSize.height - top - bottom - 1;
    }

    return patrimonies && patrimonies.length > 0 ? (
        <ScrollView
            onScroll={({ nativeEvent }) => {
                if (isCloseToBottom(nativeEvent) && !allPatrimoniesLoaded && user && selected && isFocused) {
                    getPatrimonies(
                        user.client as HttpClient,
                        selected,
                        search,
                        materialPatrimonyType,
                        materialPatrimonyMovableType,
                        materialPatrimonyImmovableType,
                        materialPatrimonyLegalProtection,
                        materialPatrimonyAdministrativeInstance,
                        materialPatrimonyAvailable,
                        false,
                    );
                }
            }}
            scrollEventThrottle={400}
        >
            <View style={{ alignItems: 'center', padding: 8 }}>
                <View style={{ flexDirection: 'row', flexWrap: 'wrap', gap: 8, width: columns * cardWidth + (columns - 1) * 8 }}>
                    {patrimonies.map((patrimony, index) => {
                        return (
                            <TouchableHighlight
                                key={index}
                                onPress={() => {
                                    if (patrimony) {
                                        //navigation.navigate('Obra', { obra });
                                    }
                                }}
                                style={{ flexGrow: 1 }}
                            >
                                <PatrimonyCard patrimony={patrimony} />
                            </TouchableHighlight>
                        );
                    })}
                </View>
            </View>
        </ScrollView>
    ) : patrimonies && patrimonies.length === 0 ? (
        <View style={{ width: '100%', height: '100%' }}>
            <View style={{ flexDirection: width < 640 ? 'column' : 'row', alignItems: 'center', justifyContent: 'center', paddingTop: 32 }}>
                <MaterialCommunityIcons name="filter-variant-remove" size={48} color={theme.inventory.notFound.textColor} />
                <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'center', minWidth: theme.minimumWidth, flexWrap: 'wrap' }}>
                    <Text
                        style={{
                            color: theme.inventory.notFound.textColor,
                            fontSize: theme.inventory.notFound.fontSize,
                            lineHeight: theme.inventory.notFound.lineHeight,
                            fontFamily: theme.inventory.notFound.fontFamily,
                            textAlign: 'center',
                            paddingLeft: width < 640 ? undefined : 16,
                            paddingTop: width < 640 ? 16 : undefined,
                        }}
                    >
                        {formatMessage({ id: 'pages.inventory_filter.not_found' })}
                    </Text>
                </View>
            </View>
        </View>
    ) : null;
}

export default InventoryFilter({ Component: Inventory, title: 'pages.inventory' });
