import { ImageResizeMode, Image as NativeImage } from 'react-native';

type ImageProps = {
    bytea: string;
    format: string;
    height?: number;
    width?: number;
    resizeMode?: ImageResizeMode;
};

function Image({ bytea, format, height, width, resizeMode }: ImageProps): JSX.Element {
    return (
        <NativeImage
            source={{
                uri: `data:image/${format};base64,${bytea}`,
            }}
            resizeMode={resizeMode}
            style={{ height, width }}
        />
    );
}

export default Image;
