/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { MessageDescriptor } from 'react-intl';
import { Text, View } from 'react-native';

import { Image } from '@base_components';
import { Theme } from '@contexts';
import { Author, Patrimony } from '@domain';
import { getYear } from '@utils';

type PatrimonyProps = {
    patrimony: Patrimony;
    formatMessage: (descriptor: MessageDescriptor) => string;
    theme: Theme;
};

function PatrimonyCard({ patrimony, formatMessage, theme }: PatrimonyProps): JSX.Element {
    const name = patrimony.name;
    const inaugurationDate = patrimony.date;

    let authors: Author[] = [];
    if (patrimony.materialPatrimony?.authors) {
        authors = patrimony.materialPatrimony.authors;
    }

    let type = '';
    if (patrimony.materialPatrimony) {
        type = `pages.inventory.enum.material-patrimony-type.${patrimony.materialPatrimony.type}`;
    }

    return (
        <View
            style={{
                width: 136,
                height: '100%',
                backgroundColor: theme.populationPatrimonyCard[patrimony.type!].backgroundColor,
            }}
        >
            <View
                style={{
                    backgroundColor: '#FFFFFF',
                    height: 140,
                    width: 136,
                }}
            >
                <View
                    style={{
                        backgroundColor: '#FFFFFF',
                        height: 136,
                        width: 136,
                        borderWidth: 1,
                        borderColor: theme.populationPatrimonyCard[patrimony.type!].backgroundColor,
                        marginBottom: 4,
                    }}
                >
                    <Image bytea={patrimony.patrimonyImage!.thumbnail} format={patrimony.patrimonyImage!.format} height={134} width={134} resizeMode="contain" />
                </View>
            </View>
            <View style={{ padding: 8 }}>
                <Text
                    style={{
                        fontFamily: theme.text.fontFamily,
                        fontSize: 12,
                        color: '#FFFFFF',
                        fontWeight: '700',
                    }}
                >{`${name}, ${getYear(inaugurationDate)}`}</Text>
                <Text
                    style={{
                        fontFamily: theme.text.fontFamily,
                        fontSize: 11,
                        color: '#FFFFFF',
                        fontWeight: '400',
                        lineHeight: 13,
                    }}
                >
                    {authors?.map((author) => author.personName ?? author.companyName).join(', ') ?? 'Desconhecida'}
                </Text>
                <Text
                    style={{
                        fontFamily: theme.text.fontFamily,
                        fontSize: 11,
                        color: '#FFFFFF',
                        fontWeight: '400',
                        lineHeight: 13,
                    }}
                >
                    {formatMessage({ id: type })}
                </Text>
            </View>
        </View>
    );
}

export default PatrimonyCard;
