import { PropsWithChildren, useState } from 'react';

import { Entypo } from '@expo/vector-icons';
import { Link, NavigationProp, useNavigation, useRoute } from '@react-navigation/native';
import { useIntl } from 'react-intl';
import { Image, Text, View, TouchableOpacity, useWindowDimensions, Pressable, Platform } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import { useAuth, useTheme } from '@contexts';
import { TarsillaUserTypEnum } from '@enum';
import { RootNavigatorParamList } from '@navigation';

type MenuProps = {
    logoClick: { screen: never; params: never };
    pages: { goTo: () => void; labelId: string; routeName: string }[];
    button: { goTo: () => void; labelId: string };
} & PropsWithChildren;

function MenuBig({ children, pages, button, logoClick }: MenuProps): JSX.Element {
    const { height } = useWindowDimensions();
    const { formatMessage } = useIntl();
    const route = useRoute();
    const { theme } = useTheme();
    const { top, bottom } = useSafeAreaInsets();

    return (
        <View>
            <View
                style={{
                    paddingVertical: 12,
                    paddingHorizontal: 24,
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    minHeight: theme.topMenu.height,
                }}
            >
                <View style={{ alignItems: 'center' }}>
                    <Link to={logoClick} style={{ height: 48 }}>
                        <Image source={require('@assets/logos/tarsilla/horizontal.png')} style={{ width: 106, height: 48 }} />
                    </Link>
                </View>
                <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                    {pages.map((page, index) => (
                        <TouchableOpacity
                            onPress={page.goTo}
                            style={{
                                borderBottomColor: theme.topMenu.textColor,
                                borderBottomWidth: route.name === page.routeName ? 2 : 0,
                                marginRight: theme.topMenu.distance,
                            }}
                            key={index}
                        >
                            <Text
                                style={{
                                    lineHeight: theme.topMenu.textLineHeight,
                                    fontSize: theme.topMenu.textFontSize,
                                    fontFamily: theme.topMenu.textFontFamily,
                                    color: theme.topMenu.textColor,
                                }}
                            >
                                {formatMessage({ id: page.labelId })}
                            </Text>
                        </TouchableOpacity>
                    ))}
                    <TouchableOpacity onPress={button.goTo}>
                        <View
                            style={{
                                backgroundColor: theme.topMenu.buttonColor,
                                borderRadius: theme.topMenu.buttonBorderRadius,
                                height: theme.topMenu.buttonHeight,
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <Text
                                style={{
                                    lineHeight: theme.topMenu.textLineHeight,
                                    fontSize: theme.topMenu.textFontSize,
                                    fontFamily: theme.topMenu.textFontFamily,
                                    color: theme.topMenu.buttonTextColor,
                                    padding: theme.topMenu.buttonPadding,
                                }}
                            >
                                {formatMessage({ id: button.labelId })}
                            </Text>
                        </View>
                    </TouchableOpacity>
                </View>
            </View>
            <View style={{ height: height - theme.topMenu.height - top - bottom }}>{children}</View>
        </View>
    );
}

function MenuSmall({ children, pages, button, logoClick }: MenuProps): JSX.Element {
    const { formatMessage } = useIntl();
    const route = useRoute();
    const { height, width } = useWindowDimensions();
    const { theme } = useTheme();
    const { top, bottom } = useSafeAreaInsets();

    const [isMenuOpen, setIsMenuOpen] = useState(false);

    return (
        <View>
            <View
                style={{
                    paddingVertical: 12,
                    paddingHorizontal: 24,
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    minHeight: theme.topMenu.height,
                }}
            >
                <View style={{ alignItems: 'center' }}>
                    <Link to={logoClick} style={{ height: 48 }}>
                        <Image source={require('@assets/logos/tarsilla/horizontal.png')} style={{ width: 106, height: 48 }} />
                    </Link>
                </View>
                <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                    <TouchableOpacity onPress={() => setIsMenuOpen(!isMenuOpen)}>
                        <Entypo name="menu" size={theme.sideMenu.iconSize} color={theme.sideMenu.iconColor} />
                    </TouchableOpacity>
                </View>
            </View>
            {isMenuOpen && (
                <View style={{ zIndex: 1 }}>
                    <Pressable
                        onPress={() => setIsMenuOpen(false)}
                        style={{
                            ...Platform.select({
                                web: {
                                    cursor: 'default',
                                },
                            }),
                            width: width - theme.sideMenu.width,
                            height,
                            backgroundColor: 'rgba(0, 0, 0, 0.5)',
                            alignSelf: 'flex-start',
                            position: 'absolute',
                            marginTop: -theme.topMenu.height,
                        }}
                    />
                    <View
                        style={{
                            width: theme.sideMenu.width,
                            height,
                            backgroundColor: theme.sideMenu.backgroundColor,
                            marginTop: -theme.topMenu.height,
                            padding: theme.sideMenu.padding,
                            alignSelf: 'flex-end',
                            position: 'absolute',
                        }}
                    >
                        {pages.map((page, index) => (
                            <TouchableOpacity
                                onPress={() => {
                                    page.goTo();
                                    setIsMenuOpen(false);
                                }}
                                style={{
                                    width: theme.sideMenu.width,
                                    paddingBottom: theme.sideMenu.distance,
                                }}
                                key={index}
                            >
                                <View
                                    style={{
                                        borderBottomColor: theme.sideMenu.textColor,
                                        borderBottomWidth: route.name === page.routeName ? 2 : 0,
                                        alignSelf: 'flex-start',
                                    }}
                                >
                                    <Text
                                        style={{
                                            lineHeight: theme.sideMenu.textLineHeight,
                                            fontSize: theme.sideMenu.textFontSize,
                                            fontFamily: theme.sideMenu.textFontFamily,
                                            color: theme.sideMenu.textColor,
                                        }}
                                    >
                                        {formatMessage({ id: page.labelId })}
                                    </Text>
                                </View>
                            </TouchableOpacity>
                        ))}
                        <TouchableOpacity onPress={button.goTo}>
                            <View
                                style={{
                                    backgroundColor: theme.sideMenu.buttonColor,
                                    borderRadius: theme.sideMenu.buttonBorderRadius,
                                    height: theme.sideMenu.buttonHeight,
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                <Text
                                    style={{
                                        lineHeight: theme.sideMenu.textLineHeight,
                                        fontSize: theme.sideMenu.textFontSize,
                                        fontFamily: theme.sideMenu.textFontFamily,
                                        color: theme.sideMenu.buttonTextColor,
                                        padding: theme.sideMenu.buttonPadding,
                                    }}
                                >
                                    {formatMessage({ id: button.labelId })}
                                </Text>
                            </View>
                        </TouchableOpacity>
                    </View>
                </View>
            )}
            <View style={{ zIndex: 0, height: height - theme.topMenu.height - top - bottom, minWidth: 320 }}>{children}</View>
        </View>
    );
}

function Menu({ children }: PropsWithChildren): JSX.Element {
    const { width } = useWindowDimensions();
    const { navigate } = useNavigation<NavigationProp<RootNavigatorParamList>>();
    const { user, logIn, logOut } = useAuth();

    const pages = [];
    let logoClick = { screen: 'SignedOutNavigator' as never, params: { screen: 'AboutUs' } as never };
    const layoutCut = 600;
    if (user) {
        if (!user.activeInstitution) {
            logoClick = { screen: 'PopulationNavigator' as never, params: { screen: 'AboutUs' } as never };
            pages.push({ goTo: () => navigate('PopulationNavigator', { screen: 'AboutUs' }), labelId: 'menu.about_us', routeName: 'AboutUs' });
            pages.push({ goTo: () => navigate('PopulationNavigator', { screen: 'Inventory' }), labelId: 'menu.inventory', routeName: 'Inventory' });
            pages.push({ goTo: () => navigate('PopulationNavigator', { screen: 'Map' }), labelId: 'menu.map', routeName: 'Map' });
            // pages.push({ goTo: () => navigate('PopulationNavigator', { screen: 'Glossary' }), labelId: 'menu.glossary', routeName: 'Glossary' });
            pages.push({ goTo: () => navigate('PopulationNavigator', { screen: 'Contact' }), labelId: 'menu.contact', routeName: 'Contact' });
        } else {
            if (user.activeInstitution.role === TarsillaUserTypEnum.DIRECTOR) {
                logoClick = { screen: 'InstitutionDirectorNavigator' as never, params: { screen: 'Inventory' } as never };
                pages.push({ goTo: () => navigate('InstitutionDirectorNavigator', { screen: 'Inventory' }), labelId: 'menu.inventory', routeName: 'Inventory' });
                pages.push({ goTo: () => navigate('InstitutionDirectorNavigator', { screen: 'Inspection' }), labelId: 'menu.inspection', routeName: 'Inspection' });
                pages.push({ goTo: () => navigate('InstitutionDirectorNavigator', { screen: 'Maintenance' }), labelId: 'menu.maintenance', routeName: 'Maintenance' });
                pages.push({ goTo: () => navigate('InstitutionDirectorNavigator', { screen: 'Glossary' }), labelId: 'menu.glossary', routeName: 'Glossary' });
            } else if (user.activeInstitution.role === TarsillaUserTypEnum.COORDINATOR) {
                logoClick = { screen: 'InstitutionCoordinatorNavigator' as never, params: { screen: 'Inventory' } as never };
                pages.push({ goTo: () => navigate('InstitutionCoordinatorNavigator', { screen: 'Inventory' }), labelId: 'menu.inventory', routeName: 'Inventory' });
                pages.push({ goTo: () => navigate('InstitutionCoordinatorNavigator', { screen: 'Inspection' }), labelId: 'menu.inspection', routeName: 'Inspection' });
                pages.push({ goTo: () => navigate('InstitutionCoordinatorNavigator', { screen: 'Maintenance' }), labelId: 'menu.maintenance', routeName: 'Maintenance' });
                pages.push({ goTo: () => navigate('InstitutionCoordinatorNavigator', { screen: 'Glossary' }), labelId: 'menu.glossary', routeName: 'Glossary' });
            } else if (user.activeInstitution.role === TarsillaUserTypEnum.TECHNICIAN) {
                logoClick = { screen: 'InstitutionTechnicianNavigator' as never, params: { screen: 'Inventory' } as never };
                pages.push({ goTo: () => navigate('InstitutionTechnicianNavigator', { screen: 'Inventory' }), labelId: 'menu.inventory', routeName: 'Inventory' });
                pages.push({ goTo: () => navigate('InstitutionTechnicianNavigator', { screen: 'Inspection' }), labelId: 'menu.inspection', routeName: 'Inspection' });
                pages.push({ goTo: () => navigate('InstitutionTechnicianNavigator', { screen: 'Maintenance' }), labelId: 'menu.maintenance', routeName: 'Maintenance' });
                pages.push({ goTo: () => navigate('InstitutionTechnicianNavigator', { screen: 'Glossary' }), labelId: 'menu.glossary', routeName: 'Glossary' });
            } else if (user.activeInstitution.role === TarsillaUserTypEnum.CARETAKER) {
                logoClick = { screen: 'InstitutionCaretakerNavigator' as never, params: { screen: 'Inventory' } as never };
                pages.push({ goTo: () => navigate('InstitutionCaretakerNavigator', { screen: 'Inventory' }), labelId: 'menu.inventory', routeName: 'Inventory' });
                pages.push({ goTo: () => navigate('InstitutionCaretakerNavigator', { screen: 'Inspection' }), labelId: 'menu.inspection', routeName: 'Inspection' });
                pages.push({ goTo: () => navigate('InstitutionCaretakerNavigator', { screen: 'Maintenance' }), labelId: 'menu.maintenance', routeName: 'Maintenance' });
                pages.push({ goTo: () => navigate('InstitutionCaretakerNavigator', { screen: 'Glossary' }), labelId: 'menu.glossary', routeName: 'Glossary' });
            }
        }
    } else {
        pages.push({ goTo: () => navigate('SignedOutNavigator', { screen: 'AboutUs' }), labelId: 'menu.about_us', routeName: 'AboutUs' });
        pages.push({ goTo: () => navigate('SignedOutNavigator', { screen: 'Contact' }), labelId: 'menu.contact', routeName: 'Contact' });
    }

    const button = user ? { goTo: async () => await logOut(), labelId: 'menu.logout' } : { goTo: async () => await logIn(), labelId: 'menu.login' };

    return width >= layoutCut ? (
        <MenuBig pages={pages} button={button} logoClick={logoClick}>
            {children}
        </MenuBig>
    ) : (
        <MenuSmall pages={pages} button={button} logoClick={logoClick}>
            {children}
        </MenuSmall>
    );
}

export default Menu;
