import axios, { AxiosError, AxiosHeaders, AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import { default as Constants } from 'expo-constants';

import { HttpClient } from '@domain';

function requestInterceptor(token: string) {
    return (config: InternalAxiosRequestConfig) => {
        const newConfig = {
            ...config,
            headers: {
                ...config.headers,
                // 'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
        } as InternalAxiosRequestConfig;
        return newConfig;
    };
}

function errorInterceptor(showErrorMessage: () => void, refreshToken?: () => Promise<void>) {
    return async (err: AxiosError) => {
        showErrorMessage?.();

        if (err.response?.status === 401) {
            await refreshToken?.();
        }
        return Promise.resolve({});
    };
}

export function httpClient(token: string, showErrorMessage: (message: string) => void, refreshToken: () => Promise<void>): HttpClient {
    return {
        get: async <T>(url: string, headers?: AxiosHeaders, message?: string): Promise<T | undefined> => {
            const showError = () => showErrorMessage(message ?? 'message.error.internal-server-error');
            const instance = axios.create();
            instance.interceptors.request.use(requestInterceptor(token), errorInterceptor(showError, refreshToken));
            instance.interceptors.response.use((response: AxiosResponse) => response, errorInterceptor(showError, refreshToken));

            const response = await instance.get<T>(`${Constants.expoConfig?.extra?.sqlApiUrl}${url}`, {
                headers,
            });
            return response.data;
        },
        post: async <Req, Res>(url: string, req: Req, headers?: AxiosHeaders, message?: string): Promise<Res | undefined> => {
            const showError = () => showErrorMessage(message ?? 'message.error.internal-server-error');
            const instance = axios.create();
            instance.interceptors.request.use(requestInterceptor(token), errorInterceptor(showError, refreshToken));
            instance.interceptors.response.use((response: AxiosResponse) => response, errorInterceptor(showError, refreshToken));

            const axiosResponse = await instance.post<Res>(`${Constants.expoConfig?.extra?.sqlApiUrl}${url}`, req, {
                headers,
            });
            return axiosResponse.data;
        },
    };
}
