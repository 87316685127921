import { useEffect } from 'react';

import { NavigationProp, NavigationState, NavigatorScreenParams, useNavigation } from '@react-navigation/native';
import { createStackNavigator, HeaderStyleInterpolators } from '@react-navigation/stack';

import { useAuth, useLoading } from '@contexts';
import { TarsillaUserTypEnum } from '@enum';

import InstitutionCaretakerNavigator, { InstitutionCaretakerNavigatorParamsList } from './InstitutionCaretakerNavigator';
import InstitutionCoordinatorNavigator, { InstitutionCoordinatorNavigatorParamsList } from './InstitutionCoordinatorNavigator';
import InstitutionDirectorNavigator, { InstitutionDirectorNavigatorParamsList } from './InstitutionDirectorNavigator';
import InstitutionTechnicianNavigator, { InstitutionTechnicianNavigatorParamsList } from './InstitutionTechnicianNavigator';
import PopulationNavigator, { PopulationNavigatorParamsList } from './PopulationNavigator';
import SignedOutNavigator, { SignedOutNavigatorParamsList } from './SignedOutNavigator';

export type RootNavigatorParamList = {
    SignedOutNavigator: NavigatorScreenParams<SignedOutNavigatorParamsList>;
    PopulationNavigator: NavigatorScreenParams<PopulationNavigatorParamsList>;
    InstitutionDirectorNavigator: NavigatorScreenParams<InstitutionDirectorNavigatorParamsList>;
    InstitutionCoordinatorNavigator: NavigatorScreenParams<InstitutionCoordinatorNavigatorParamsList>;
    InstitutionTechnicianNavigator: NavigatorScreenParams<InstitutionTechnicianNavigatorParamsList>;
    InstitutionCaretakerNavigator: NavigatorScreenParams<InstitutionCaretakerNavigatorParamsList>;
};

const StackNavigator = createStackNavigator<RootNavigatorParamList>();

export function RootNavigator(): JSX.Element | null {
    const { navigate, getState } = useNavigation<NavigationProp<RootNavigatorParamList>>();
    const { user } = useAuth();
    const { initialLoading } = useLoading();

    const actualState = getState();

    function isRoute(state?: NavigationState, route?: string): boolean {
        return state?.routes != null && state.routes.length > 0 && state.routes[state.routes.length - 1].name === route;
    }

    useEffect(() => {
        if (!initialLoading && user) {
            if (!user.activeInstitution) {
                if (!isRoute(actualState, 'PopulationNavigator')) {
                    navigate('PopulationNavigator', { screen: 'AboutUs' });
                }
            } else {
                if (user.activeInstitution.role === TarsillaUserTypEnum.DIRECTOR) {
                    if (!isRoute(actualState, 'InstitutionDirectorNavigator')) {
                        navigate('InstitutionDirectorNavigator', { screen: 'Inventory' });
                    }
                } else if (user.activeInstitution.role === TarsillaUserTypEnum.COORDINATOR) {
                    if (!isRoute(actualState, 'InstitutionCoordinatorNavigator')) {
                        navigate('InstitutionCoordinatorNavigator', { screen: 'Inventory' });
                    }
                } else if (user.activeInstitution.role === TarsillaUserTypEnum.TECHNICIAN) {
                    if (!isRoute(actualState, 'InstitutionTechnicianNavigator')) {
                        navigate('InstitutionTechnicianNavigator', { screen: 'Inventory' });
                    }
                } else if (user.activeInstitution.role === TarsillaUserTypEnum.CARETAKER) {
                    if (!isRoute(actualState, 'InstitutionCaretakerNavigator')) {
                        navigate('InstitutionCaretakerNavigator', { screen: 'Inventory' });
                    }
                }
            }
        } else if (!initialLoading) {
            navigate('SignedOutNavigator', { screen: 'AboutUs' });
        }
    }, [initialLoading, user]);

    return (
        <StackNavigator.Navigator
            screenOptions={{
                headerStyleInterpolator: HeaderStyleInterpolators.forUIKit,
            }}
        >
            <StackNavigator.Screen name="SignedOutNavigator" component={SignedOutNavigator} options={{ headerShown: false }} />
            <StackNavigator.Screen name="PopulationNavigator" component={PopulationNavigator} options={{ headerShown: false }} />
            <StackNavigator.Screen name="InstitutionDirectorNavigator" component={InstitutionDirectorNavigator} options={{ headerShown: false }} />
            <StackNavigator.Screen name="InstitutionCoordinatorNavigator" component={InstitutionCoordinatorNavigator} options={{ headerShown: false }} />
            <StackNavigator.Screen name="InstitutionTechnicianNavigator" component={InstitutionTechnicianNavigator} options={{ headerShown: false }} />
            <StackNavigator.Screen name="InstitutionCaretakerNavigator" component={InstitutionCaretakerNavigator} options={{ headerShown: false }} />
        </StackNavigator.Navigator>
    );
}
