import { HeaderStyleInterpolators, createStackNavigator } from '@react-navigation/stack';

import { AboutUs, Contact, Glossary, PopulationInventory, PopulationMap } from '@pages';

import SafeViewFunction from './SafeViewFunction';

const StackNavigator = createStackNavigator<PopulationNavigatorParamsList>();

export type PopulationNavigatorParamsList = {
    AboutUs: undefined;
    Inventory: undefined;
    Map: undefined;
    Glossary: undefined;
    Contact: undefined;
};

function PopulationNavigator(): JSX.Element {
    return (
        <StackNavigator.Navigator
            screenOptions={{
                headerStyleInterpolator: HeaderStyleInterpolators.forUIKit,
            }}
        >
            <StackNavigator.Screen name="AboutUs" component={SafeViewFunction({ Component: AboutUs })} options={{ headerShown: false }} />
            <StackNavigator.Screen name="Inventory" component={SafeViewFunction({ Component: PopulationInventory })} options={{ headerShown: false }} />
            <StackNavigator.Screen name="Map" component={SafeViewFunction({ Component: PopulationMap })} options={{ headerShown: false }} />
            <StackNavigator.Screen name="Glossary" component={SafeViewFunction({ Component: Glossary })} options={{ headerShown: false }} />
            <StackNavigator.Screen name="Contact" component={SafeViewFunction({ Component: Contact })} options={{ headerShown: false }} />
        </StackNavigator.Navigator>
    );
}

export default PopulationNavigator;
