import { useEffect, useState } from 'react';

import { MaterialIcons } from '@expo/vector-icons';
import { useIntl } from 'react-intl';
import { useToast as useFastToast } from 'react-native-fast-toast';

type ToastProps = {
    show: (message: string, icon?: JSX.Element) => void;
};

export function useSuccessToast(): ToastProps {
    const { show, hide } = useFastToast();
    const { formatMessage } = useIntl();

    const [toast, setToast] = useState<{ message: string; icon?: JSX.Element } | null>(null);

    useEffect(() => {
        if (toast != null) {
            show(formatMessage({ id: toast.message }), {
                icon: toast.icon ?? <MaterialIcons name="check-circle" size={24} color="#FFFFFF" />,
                type: 'success',
                duration: 3000,
                onPress: (id) => hide(id),
                textStyle: { color: '#FFFFFF' },
            });
            setToast(null);
        }
    }, [toast]);

    return {
        show: (message: string, icon?: JSX.Element) => setToast({ message, icon }),
    };
}

export function useErrorToast(): ToastProps {
    const { show, hide } = useFastToast();
    const { formatMessage } = useIntl();

    const [toast, setToast] = useState<{ message: string; icon?: JSX.Element } | null>(null);

    useEffect(() => {
        if (toast != null) {
            show(formatMessage({ id: toast.message }), {
                icon: toast.icon ?? <MaterialIcons name="error" size={24} color="#FFFFFF" />,
                type: 'danger',
                duration: 3000,
                onPress: (id) => hide(id),
                textStyle: { color: '#FFFFFF' },
            });
            setToast(null);
        }
    }, [toast]);

    return {
        show: (message: string, icon?: JSX.Element) => setToast({ message, icon }),
    };
}

export function useWarningToast(): ToastProps {
    const { show, hide } = useFastToast();
    const { formatMessage } = useIntl();

    const [toast, setToast] = useState<{ message: string; icon?: JSX.Element } | null>(null);

    useEffect(() => {
        if (toast != null) {
            show(formatMessage({ id: toast.message }), {
                icon: toast.icon ?? <MaterialIcons name="warning" size={24} color="#FFFFFF" />,
                type: 'warning',
                duration: 3000,
                onPress: (id) => hide(id),
                textStyle: { color: '#FFFFFF' },
            });
            setToast(null);
        }
    }, [toast]);

    return {
        show: (message: string, icon?: JSX.Element) => setToast({ message, icon }),
    };
}
