import { HeaderStyleInterpolators, createStackNavigator } from '@react-navigation/stack';

import { Glossary, InstitutionInventory, MaintenanceBacklog, MaintenanceKanban } from '@pages';

import SafeViewFunction from './SafeViewFunction';

const StackNavigator = createStackNavigator<InstitutionTechnicianNavigatorParamsList>();

export type InstitutionTechnicianNavigatorParamsList = {
    Inventory: undefined;
    Inspection: undefined;
    Maintenance: undefined;
    Glossary: undefined;
};

function InstitutionTechnicianNavigator(): JSX.Element {
    return (
        <StackNavigator.Navigator
            screenOptions={{
                headerStyleInterpolator: HeaderStyleInterpolators.forUIKit,
            }}
        >
            <StackNavigator.Screen name="Inventory" component={SafeViewFunction({ Component: InstitutionInventory })} options={{ headerShown: false }} />
            <StackNavigator.Screen name="Inspection" component={SafeViewFunction({ Component: MaintenanceBacklog })} options={{ headerShown: false }} />
            <StackNavigator.Screen name="Maintenance" component={SafeViewFunction({ Component: MaintenanceKanban })} options={{ headerShown: false }} />
            <StackNavigator.Screen name="Glossary" component={SafeViewFunction({ Component: Glossary })} options={{ headerShown: false }} />
        </StackNavigator.Navigator>
    );
}

export default InstitutionTechnicianNavigator;
