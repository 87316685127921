import React, { FunctionComponent } from 'react';

import { SafeAreaView } from 'react-native-safe-area-context';

import { Loading } from '@base_components';
import { useLoading } from '@contexts';
import { Menu } from '@menu';

type NavigationModalProps = {
    Component: FunctionComponent;
};

function SafeLoading(): JSX.Element | null {
    const { initialLoading, loading } = useLoading();

    return initialLoading === true ? <Loading type="initialLoading" /> : loading === true ? <Loading /> : null;
}

function SafeViewFunction({ Component }: NavigationModalProps): FunctionComponent {
    return (props) => {
        return (
            <SafeAreaView edges={['bottom', 'top']}>
                <SafeLoading />
                <Menu>
                    <Component {...props} />
                </Menu>
            </SafeAreaView>
        );
    };
}

export default SafeViewFunction;
