import { branco, magenta, rosa } from './cores';
import fonts from './fonts';
import { Theme } from '../domain';

const DarkTheme: Theme = {
    mode: 'light',
    background: branco,
    navigation: {
        background: branco,
        border: rosa,
        card: branco,
        notification: magenta,
        primary: magenta,
        text: magenta,
        active: magenta,
        inactive: rosa,
    },
    button: {
        fontFamily: fonts.medium,
        fontSize: 12,
        textColor: magenta,
        borderColor: magenta,
        backgroundColor: rosa,
        pressed: {
            textColor: rosa,
            borderColor: rosa,
            backgroundColor: magenta,
        },
    },
    text: {
        fontFamily: fonts.medium,
        fontSize: 12,
        textColor: magenta,
    },
    topMenu: {
        height: 72,
        backgroundColor: '#FFF',
        textColor: '#442772',
        textFontFamily: fonts.bold,
        textFontSize: 20,
        textLineHeight: 24,
        distance: 16,
        buttonColor: '#442772',
        buttonTextColor: '#FFF',
    },
};

export default DarkTheme;
