/* eslint-disable @typescript-eslint/no-explicit-any */
import { Dispatch, SetStateAction, useState } from 'react';

import { MaterialIcons } from '@expo/vector-icons';
import { ViewStyle } from 'react-native';
import DropDownPicker from 'react-native-dropdown-picker';

type DropdownProps = {
    type?: 'rounded' | 'line' | 'arrow' | 'rounded-highlighted' | 'line-highlighted' | 'arrow-highlighted';
    value: any | null;
    setValue: Dispatch<SetStateAction<any | null>>;
    items: { value: any; label: string }[];
    placeholder: string;
    color: string;
    selectedColor: string;
    backgroundColor: string;
    fontSize?: number;
    fontFamily?: string;
    unselectable?: boolean;
    containerStyle?: ViewStyle;
    dropDownContainerStyle?: ViewStyle;
    multiple?: boolean;
    multipleText?: string;
};

function Dropdown({
    type = 'rounded',
    value,
    setValue,
    items,
    placeholder,
    color,
    selectedColor,
    backgroundColor,
    fontSize,
    fontFamily,
    unselectable = true,
    containerStyle,
    dropDownContainerStyle,
    multiple,
    multipleText,
}: DropdownProps): JSX.Element {
    const [open, setOpen] = useState(false);

    const isLine = type?.includes('line');
    const isArrow = type?.includes('arrow');
    const isRounded = type?.includes('rounded');
    const isHighlighted = type?.includes('highlighted');

    const valueFixed = isArrow && !multiple && value != null ? [value] : value == null ? (multiple === true ? [] : null) : value;

    const setArrowSingleValue = (item: React.SetStateAction<any | null>) => {
        const valueFromSelect = item(valueFixed);
        const newValue = valueFromSelect[valueFromSelect.length - 1];
        setValue(unselectable === true && !multiple && newValue === value ? null : newValue);
        setOpen(false);
    };

    return (
        <DropDownPicker
            open={open}
            setOpen={setOpen}
            setValue={isArrow && !multiple ? setArrowSingleValue : setValue}
            value={valueFixed}
            items={items}
            placeholder={placeholder}
            multiple={isArrow || multiple}
            multipleText={isArrow && !multiple === true ? items.find((i) => i.value === value)?.label : multipleText ?? placeholder}
            containerStyle={[
                {
                    width: 'auto',
                },
                containerStyle,
            ]}
            style={{
                backgroundColor,
                borderColor: isHighlighted && value != null && (!multiple || value.length > 0) ? selectedColor : color,
                borderWidth: isRounded ? 2 : 0,
                borderBottomWidth: isRounded ? undefined : 2,
                borderRadius: isRounded ? 15 : 0,
                minHeight: isRounded ? 30 : 22,
                paddingTop: isRounded ? undefined : 0,
                paddingBottom: isRounded ? undefined : 0,
                paddingLeft: isRounded ? undefined : 0,
                paddingRight: isRounded ? undefined : 0,
            }}
            dropDownContainerStyle={[
                {
                    backgroundColor: isArrow ? 'transparent' : backgroundColor,
                    borderColor: !isArrow ? color : backgroundColor,
                    borderWidth: !isArrow ? 2 : 0,
                    width: 'auto',
                    minWidth: !isArrow ? '100%' : undefined,
                },
                dropDownContainerStyle,
                (isArrow || isLine) && {
                    borderRadius: 0,
                },
            ]}
            textStyle={{
                color: isHighlighted && value != null && (!multiple || value.length > 0) ? selectedColor : color,
                fontSize,
                fontFamily,
            }}
            listItemContainerStyle={{
                backgroundColor,
            }}
            labelProps={{
                numberOfLines: 1,
                ellipsizeMode: 'tail',
            }}
            itemProps={{
                activeOpacity: isArrow ? 0.95 : undefined,
            }}
            itemLabelProps={{
                numberOfLines: 1,
                ellipsizeMode: 'tail',
            }}
            itemSeparator
            itemSeparatorStyle={{
                backgroundColor: '#FFFFFF',
                marginHorizontal: 8,
            }}
            selectedItemLabelStyle={{
                color: selectedColor,
            }}
            listItemLabelStyle={{
                color,
            }}
            arrowIconContainerStyle={{
                marginLeft: 0,
            }}
            showTickIcon={false}
            //listMode="MODAL"
            //modalContentContainerStyle={{
            //    backgroundColor,
            //}
            ArrowDownIconComponent={() => <MaterialIcons name="keyboard-arrow-down" size={20} color={isHighlighted && value != null && (!multiple || value.length > 0) ? selectedColor : color} />}
            ArrowUpIconComponent={() => <MaterialIcons name="keyboard-arrow-up" size={20} color={isHighlighted && value != null && (!multiple || value.length > 0) ? selectedColor : color} />}
            searchContainerStyle={
                isArrow && {
                    marginLeft: 8,
                    padding: 0,
                    width: 0,
                    height: 0,
                    backgroundColor: 'transparent',
                    borderStyle: 'solid',
                    borderLeftWidth: 12,
                    borderRightWidth: 12,
                    borderBottomWidth: 12,
                    borderLeftColor: 'transparent',
                    borderRightColor: 'transparent',
                    borderBottomColor: backgroundColor,
                }
            }
            searchTextInputStyle={
                isArrow && {
                    display: 'none',
                }
            }
            searchable={isArrow}
            closeOnBackPressed
            dropDownDirection="BOTTOM"
        />
    );
}

export default Dropdown;
