/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useState, useEffect } from 'react';

import { MaterialCommunityIcons } from '@expo/vector-icons';
import { useIsFocused } from '@react-navigation/native';
import { useIntl } from 'react-intl';

import { Map as BaseMap } from '@base_components';
import { useAuth, useLoading, useTheme } from '@contexts';
import { HttpClient, Patrimony } from '@domain';
import { ImmovablePatrimonyTypeEnum, MaterialPatrimonyAdministrativeInstanceTypeEnum, MaterialPatrimonyLegalProtectionTypeEnum, MaterialPatrimonyTypeEnum, MovablePatrimonyTypeEnum } from '@enum';
import { useWarningToast } from '@hooks';

import PatrimonyCard from './PatrimonyCard';
import { InventoryFilter } from '../../inventory_filter';
import { InventoryFilterComponentProps } from '../../inventory_filter/InventoryFilter';

type MarkerProps = {
    position: { latitude: string; longitude: string };
    color?: string;
    infoWindow?: () => Promise<JSX.Element | null>;
};

export type RequestProps = {
    cityId: string | null;
    patrimonyName: string | null;
    materialPatrimonyType: MaterialPatrimonyTypeEnum | null;
    materialPatrimonyMovableType: MovablePatrimonyTypeEnum[] | null;
    materialPatrimonyImmovableType: ImmovablePatrimonyTypeEnum[] | null;
    materialPatrimonyLegalProtection: (MaterialPatrimonyLegalProtectionTypeEnum | 'NONE')[] | null;
    materialPatrimonyAdministrativeInstance: MaterialPatrimonyAdministrativeInstanceTypeEnum[] | null;
    materialPatrimonyAvailable: boolean[] | null;
};

function Map({
    selected,
    search,
    materialPatrimonyType,
    materialPatrimonyMovableType,
    materialPatrimonyImmovableType,
    materialPatrimonyLegalProtection,
    materialPatrimonyAdministrativeInstance,
    materialPatrimonyAvailable,
}: InventoryFilterComponentProps): JSX.Element {
    const isFocused = useIsFocused();
    const { formatMessage } = useIntl();
    const { theme } = useTheme();
    const { user } = useAuth();
    const { setLoading } = useLoading();
    const { show } = useWarningToast();

    const [markers, setMarkers] = useState<MarkerProps[]>([]);

    async function getMarkers(client: HttpClient, selected: string, search: string | null) {
        setLoading(true);
        const patrimonies = await client.post<RequestProps, Patrimony[]>('/population/map', {
            cityId: selected,
            patrimonyName: search,
            materialPatrimonyType,
            materialPatrimonyMovableType,
            materialPatrimonyImmovableType,
            materialPatrimonyLegalProtection,
            materialPatrimonyAdministrativeInstance,
            materialPatrimonyAvailable: materialPatrimonyAvailable?.map((item) => item !== 'true') ?? null,
        });
        if (patrimonies) {
            if (patrimonies.length === 0) {
                show('pages.inventory_filter.not_found', <MaterialCommunityIcons name="filter-variant-remove" size={24} color="#FFFFFF" />);
            }
            setMarkers(
                patrimonies.map((patrimony) => ({
                    position: { latitude: patrimony.materialPatrimony!.location!.latitude!.toString() ?? '', longitude: patrimony.materialPatrimony!.location!.longitude!.toString() ?? '' },
                    color: theme.populationPatrimonyCard[patrimony.type!].backgroundColor,
                    infoWindow: async () => {
                        setLoading(true);
                        const patrimonyCard = await client.get<Patrimony>(`/population/card?id=${patrimony.id}`);
                        setLoading(false);

                        if (patrimonyCard == null) {
                            return null;
                        }
                        return <PatrimonyCard patrimony={patrimonyCard!} formatMessage={formatMessage} theme={theme} />;
                    },
                })),
            );
        }
        setLoading(false);
    }

    useEffect(() => {
        if (user && selected && isFocused) {
            getMarkers(user.client as HttpClient, selected, search);
        }
    }, [
        user,
        selected,
        search,
        materialPatrimonyType,
        materialPatrimonyMovableType,
        materialPatrimonyImmovableType,
        materialPatrimonyLegalProtection,
        materialPatrimonyAdministrativeInstance,
        materialPatrimonyAvailable,
        isFocused,
    ]);

    return <BaseMap markers={markers} />;
}

export default InventoryFilter({ Component: Map, title: 'pages.map.title' });
