import { View, ActivityIndicator, useWindowDimensions } from 'react-native';

import { useTheme } from '@contexts';

function Loading({ type }: { type?: 'initialLoading' }): JSX.Element {
    const { width, height } = useWindowDimensions();
    const { theme } = useTheme();

    return (
        <View
            style={[
                {
                    position: 'absolute',
                    left: 0,
                    right: 0,
                    top: 0,
                    bottom: 0,
                    opacity: type === 'initialLoading' ? 1 : 0.5,
                    backgroundColor: type === 'initialLoading' ? 'white' : 'black',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width,
                    height,
                    zIndex: 1,
                },
            ]}
        >
            <ActivityIndicator size={theme.activityIndicator.size} color={theme.activityIndicator.color} />
        </View>
    );
}

export default Loading;
