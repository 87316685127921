import { StyleSheet, TextStyle, ViewStyle, useWindowDimensions } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import { useTheme } from '@contexts';

type StylesProps = {
    gridVertical: ViewStyle;
    gridHorizontal: ViewStyle;
    title: ViewStyle;
    titleText: TextStyle;
    titleAllowedToReceive: ViewStyle;
    titleReceiving: ViewStyle;
    column: ViewStyle;
    columnDrax: ViewStyle;
    row: ViewStyle;
    rowDrax: ViewStyle;
    kanbanAllowedToReceive: ViewStyle;
    kanbanReceiving: ViewStyle;
    card: ViewStyle;
    cardDragging: ViewStyle;
};

function useStyles(): StylesProps {
    const { theme } = useTheme();
    const { height } = useWindowDimensions();
    const { top, bottom } = useSafeAreaInsets();

    return StyleSheet.create({
        gridVertical: {
            marginHorizontal: 12,
        },
        gridHorizontal: {
            marginHorizontal: 12,
            marginBottom: 12,
        },
        title: {
            backgroundColor: theme.kanban.titleBackgroundColor,
            padding: 12,
            alignItems: 'center',
        },
        titleAllowedToReceive: {
            borderColor: theme.kanban.titleAllowedToReceiveBorderColor,
            borderWidth: 4,
            borderStyle: 'dashed',
        },
        titleReceiving: {
            backgroundColor: theme.kanban.titleReceivingBackgroundColor,
            opacity: 0.6,
        },
        titleText: {
            fontFamily: theme.kanban.titleFontFamily,
            fontSize: theme.kanban.titleFontSize,
            lineHeight: theme.kanban.titleLineHeight,
            color: theme.kanban.titleColor,
        },
        column: {
            marginRight: 12,
        },
        columnDrax: {
            minHeight: height - theme.topMenu.height - top - bottom - 12 - 46,
            height: '100%',
            minWidth: 204,
            backgroundColor: theme.kanban.backgroundColor,
        },
        row: {
            marginBottom: 12,
        },
        rowDrax: {
            minHeight: (height - theme.topMenu.height - top - bottom - 12) / 3 - 46,
            minWidth: 204,
            backgroundColor: theme.kanban.backgroundColor,
        },
        kanbanAllowedToReceive: {
            borderColor: theme.kanban.allowedToReceiveBorderColor,
            borderWidth: 4,
            borderStyle: 'dashed',
        },
        kanbanReceiving: {
            backgroundColor: theme.kanban.receivingBackgroundColor,
            opacity: 0.6,
        },
        card: {
            minWidth: 180,
            minHeight: 90,
            backgroundColor: theme.kanban.cardBackgroundColor,
            padding: 8,
            margin: 12,
        },
        cardDragging: {
            backgroundColor: theme.kanban.cardDraggingBackgroundColor,
            borderColor: theme.kanban.cardDraggingBorderColor,
            borderWidth: 2,
        },
    });
}

export default useStyles;
