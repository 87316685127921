import { PropsWithChildren, useState } from 'react';

import { IntlProvider } from 'react-intl';

import locales from './locales';

function Provider({ children }: PropsWithChildren): JSX.Element {
    const [intl, _] = useState<{ language: string; messages: Record<string, string> }>({
        language: 'pt-BR',
        messages: locales['pt-BR'],
    });

    return (
        <IntlProvider locale={intl.language} messages={intl.messages}>
            {children}
        </IntlProvider>
    );
}

export default Provider;
