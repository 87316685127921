/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState } from 'react';

import { FontAwesome } from '@expo/vector-icons';
import { useIntl } from 'react-intl';
import { Platform, Text, View, useWindowDimensions } from 'react-native';
import { DraxProvider, DraxView } from 'react-native-drax';
import { Col, Grid, Row } from 'react-native-easy-grid';
import { ScrollView, TouchableHighlight } from 'react-native-gesture-handler';

import { DraxList } from './react-native-drax/DraxList';
import useStyles from './styles';

type KanbanProps = {
    horizontal?: boolean;
    columns: {
        id: string;
        title: string;
        renderItem: (item: any) => JSX.Element;
        items: any[];
        setItems: (items: any[]) => void;
    }[];
};

function KanbanVertical({ columns }: Omit<KanbanProps, 'horizontal'>): JSX.Element {
    const [isKanbanAllowedToReceive, setIsKanbanAllowedToReceive] = useState<boolean[]>(columns.map(() => false));
    const [isKanbanReceiving, setIsKanbanReceiving] = useState<boolean[]>(columns.map(() => false));

    const { width } = useWindowDimensions();
    const { formatMessage } = useIntl();

    const styles = useStyles();

    return (
        <DraxProvider>
            <Grid style={styles.gridVertical}>
                <Row>
                    {columns.map((column, index) => (
                        <Col style={[styles.column, index + 1 === columns.length && { marginRight: 0 }]} key={column.id}>
                            <View style={styles.title}>
                                <Text style={styles.titleText}>{formatMessage({ id: column.title })}</Text>
                            </View>
                            <DraxView
                                id={column.id}
                                style={[{ height: '100%' }]}
                                receptive={isKanbanReceiving[index]}
                                draggable={false}
                                onMonitorDragStart={(event) => {
                                    const { dragged } = event;

                                    const newIsColumnAllowed = [...isKanbanAllowedToReceive].reduce<boolean[]>((result, item, isColumnAllowedIndex) => {
                                        if (dragged.parentId?.includes(column.id)) {
                                            if (index === isColumnAllowedIndex) {
                                                result.push(false);
                                            } else {
                                                result.push(true);
                                            }
                                        }
                                        return result;
                                    }, []);
                                    if (newIsColumnAllowed.length > 0) {
                                        setIsKanbanAllowedToReceive(newIsColumnAllowed);
                                    }
                                }}
                                onMonitorDragEnter={(event) => {
                                    const { dragged } = event;

                                    const newIsReceiving = [...isKanbanReceiving].reduce<boolean[]>((result, item, isReceivingIndex) => {
                                        if (index === isReceivingIndex) {
                                            result.push(!dragged.parentId?.includes(column.id));
                                        } else {
                                            result.push(false);
                                        }
                                        return result;
                                    }, []);
                                    setIsKanbanReceiving(newIsReceiving);
                                }}
                                onReceiveDragExit={() => {
                                    const newIsReceiving = [...isKanbanReceiving].reduce<boolean[]>((result, item, isReceivingIndex) => {
                                        if (index === isReceivingIndex) {
                                            result.push(false);
                                        } else {
                                            result.push(item);
                                        }
                                        return result;
                                    }, []);
                                    setIsKanbanReceiving(newIsReceiving);
                                }}
                                onMonitorDragDrop={(event) => {
                                    if (isKanbanReceiving[index] && !event.dragged.parentId?.includes(column.id)) {
                                        const { index: draggedIndex } = event.dragged.payload;

                                        const parentColumn = columns.find((columnFind) => event.dragged.parentId?.includes(columnFind.id));

                                        const draggedItem = parentColumn?.items[draggedIndex];

                                        column.setItems([...column.items, draggedItem]);
                                        parentColumn?.setItems(
                                            parentColumn?.items.reduce<string[]>((result, item) => {
                                                if (item !== draggedItem) {
                                                    result.push(item);
                                                }
                                                return result;
                                            }, []),
                                        );
                                    }
                                }}
                            >
                                <DraxList
                                    style={[
                                        styles.columnDrax,
                                        isKanbanAllowedToReceive[index] && styles.kanbanAllowedToReceive,
                                        isKanbanReceiving[index] && styles.kanbanReceiving,
                                        { width: (width - 12 * (columns.length + 1)) / 3 },
                                    ]}
                                    reorderable
                                    scrollEnabled={false}
                                    id={`${column.id}-list`}
                                    longPressDelay={Platform.select({ web: 0, default: 100 })}
                                    data={column.items}
                                    renderItemContent={({ item, index: dragListIndex }, { viewState: itemViewState }) => {
                                        const draggingItem = itemViewState && itemViewState.dragStatus !== 0;

                                        return (
                                            <View id={`${column.id}-list-${dragListIndex}`} style={[styles.card, draggingItem && styles.cardDragging, dragListIndex !== 0 && { marginTop: 0 }]}>
                                                <column.renderItem item={item} />
                                            </View>
                                        );
                                    }}
                                    onItemReorder={({ fromIndex, toIndex }) => {
                                        const newItems = column.items.slice();
                                        newItems.splice(toIndex, 0, newItems.splice(fromIndex, 1)[0]);
                                        column.setItems(newItems);
                                    }}
                                    keyExtractor={(_, dragListIndex) => `${column.id}-list-${dragListIndex}`}
                                    viewPropsExtractor={() => ({
                                        animateSnapback: false,
                                        onDragEnd: () => {
                                            setIsKanbanAllowedToReceive(columns.map(() => false));
                                            setIsKanbanReceiving(columns.map(() => false));
                                        },
                                        onDragDrop: () => {
                                            setIsKanbanAllowedToReceive(columns.map(() => false));
                                            setIsKanbanReceiving(columns.map(() => false));
                                        },
                                    })}
                                />
                            </DraxView>
                        </Col>
                    ))}
                </Row>
                <Row style={{ minHeight: 12, maxHeight: 12, backgroundColor: '#FFFFFF' }} />
            </Grid>
        </DraxProvider>
    );
}

function KanbanHorizontal({ columns }: Omit<KanbanProps, 'horizontal'>): JSX.Element {
    const [isKanbanAllowedToReceive, setIsKanbanAllowedToReceive] = useState<boolean[]>(columns.map(() => false));
    const [isKanbanReceiving, setIsKanbanReceiving] = useState<boolean[]>(columns.map(() => false));

    const { formatMessage } = useIntl();

    const styles = useStyles();

    return (
        <DraxProvider>
            <View style={styles.gridHorizontal}>
                {columns.map((column, index) => (
                    <View key={column.id}>
                        <View style={styles.title}>
                            <Text style={styles.titleText}>{formatMessage({ id: column.title })}</Text>
                        </View>
                        <DraxView
                            id={column.id}
                            style={[styles.row, index + 1 === columns.length && { marginBottom: 0 }]}
                            receptive={isKanbanReceiving[index]}
                            draggable={false}
                            onMonitorDragStart={(event) => {
                                const { dragged } = event;

                                const newIsColumnAllowed = [...isKanbanAllowedToReceive].reduce<boolean[]>((result, item, isColumnAllowedIndex) => {
                                    if (dragged.parentId?.includes(column.id)) {
                                        if (index === isColumnAllowedIndex) {
                                            result.push(false);
                                        } else {
                                            result.push(true);
                                        }
                                    }
                                    return result;
                                }, []);
                                if (newIsColumnAllowed.length > 0) {
                                    setIsKanbanAllowedToReceive(newIsColumnAllowed);
                                }
                            }}
                            onMonitorDragEnter={(event) => {
                                const { dragged } = event;

                                const newIsReceiving = [...isKanbanReceiving].reduce<boolean[]>((result, item, isReceivingIndex) => {
                                    if (index === isReceivingIndex) {
                                        result.push(!dragged.parentId?.includes(column.id));
                                    } else {
                                        result.push(false);
                                    }
                                    return result;
                                }, []);
                                setIsKanbanReceiving(newIsReceiving);
                            }}
                            onReceiveDragExit={() => {
                                const newIsReceiving = [...isKanbanReceiving].reduce<boolean[]>((result, item, isReceivingIndex) => {
                                    if (index === isReceivingIndex) {
                                        result.push(false);
                                    } else {
                                        result.push(item);
                                    }
                                    return result;
                                }, []);
                                setIsKanbanReceiving(newIsReceiving);
                            }}
                            onMonitorDragDrop={(event) => {
                                if (isKanbanReceiving[index] && !event.dragged.parentId?.includes(column.id)) {
                                    const { index: draggedIndex } = event.dragged.payload;

                                    const parentColumn = columns.find((columnFind) => event.dragged.parentId?.includes(columnFind.id));

                                    const draggedItem = parentColumn?.items[draggedIndex];

                                    column.setItems([...column.items, draggedItem]);
                                    parentColumn?.setItems(
                                        parentColumn?.items.reduce<string[]>((result, item) => {
                                            if (item !== draggedItem) {
                                                result.push(item);
                                            }
                                            return result;
                                        }, []),
                                    );
                                }
                            }}
                        >
                            <DraxList
                                style={[styles.rowDrax, isKanbanAllowedToReceive[index] && styles.kanbanAllowedToReceive, isKanbanReceiving[index] && styles.kanbanReceiving]}
                                reorderable
                                scrollEnabled={false}
                                id={`${column.id}-list`}
                                longPressDelay={Platform.select({ web: 0, default: 100 })}
                                data={column.items}
                                renderItemContent={({ item, index: dragListIndex }, { viewState: itemViewState }) => {
                                    const draggingItem = itemViewState && itemViewState.dragStatus !== 0;
                                    return (
                                        <View id={`${column.id}-list-${dragListIndex}`} style={[styles.card, draggingItem && styles.cardDragging, dragListIndex !== 0 && { marginTop: 0 }]}>
                                            <column.renderItem item={item} />
                                        </View>
                                    );
                                }}
                                onItemReorder={({ fromIndex, toIndex }) => {
                                    const newItems = column.items.slice();
                                    newItems.splice(toIndex, 0, newItems.splice(fromIndex, 1)[0]);
                                    column.setItems(newItems);
                                }}
                                keyExtractor={(_, dragListIndex) => `${column.id}-list-${dragListIndex}`}
                                viewPropsExtractor={() => ({
                                    animateSnapback: false,
                                    onDragEnd: () => {
                                        setIsKanbanAllowedToReceive(columns.map(() => false));
                                        setIsKanbanReceiving(columns.map(() => false));
                                    },
                                    onDragDrop: () => {
                                        setIsKanbanAllowedToReceive(columns.map(() => false));
                                        setIsKanbanReceiving(columns.map(() => false));
                                    },
                                })}
                            />
                        </DraxView>
                    </View>
                ))}
            </View>
        </DraxProvider>
    );
}

function KanbanSmall({ columns }: Omit<KanbanProps, 'horizontal'>): JSX.Element {
    const [isKanbanAllowedToReceive, setIsKanbanAllowedToReceive] = useState<boolean[]>(columns.map(() => false));
    const [isKanbanReceiving, setIsKanbanReceiving] = useState<boolean[]>(columns.map(() => false));
    const [isColumnOpen, setIsColumnOpen] = useState<boolean[]>(columns.map(() => false));

    const { formatMessage } = useIntl();

    const styles = useStyles();

    const titlePress = (index: number) => {
        const newIsColumnOpen = [...isColumnOpen].reduce<boolean[]>((result, item, isColumnOpenIndex) => {
            if (index === isColumnOpenIndex) {
                result.push(!isColumnOpen[index]);
            } else {
                result.push(false);
            }
            return result;
        }, []);
        setIsColumnOpen(newIsColumnOpen);
    };

    return (
        <DraxProvider>
            <View style={styles.gridHorizontal}>
                {columns.map((column, index) => (
                    <View key={column.id}>
                        {isColumnOpen[index] && (
                            <TouchableHighlight onPress={() => titlePress(index)}>
                                <View style={[styles.title, { flexDirection: 'row', justifyContent: 'space-between' }]}>
                                    <Text style={styles.titleText}>{formatMessage({ id: column.title })}</Text>
                                    <FontAwesome style={{ fontSize: 24, color: '#442772' }} name="arrow-circle-up" />
                                </View>
                            </TouchableHighlight>
                        )}
                        <DraxView
                            id={column.id}
                            style={[styles.row, index + 1 === columns.length && { marginBottom: 0 }]}
                            receptive={isKanbanReceiving[index]}
                            draggable={false}
                            onMonitorDragStart={(event) => {
                                const { dragged } = event;

                                const newIsColumnAllowed = [...isKanbanAllowedToReceive].reduce<boolean[]>((result, item, isColumnAllowedIndex) => {
                                    if (dragged.parentId?.includes(column.id)) {
                                        if (index === isColumnAllowedIndex) {
                                            result.push(false);
                                        } else {
                                            result.push(true);
                                        }
                                    }
                                    return result;
                                }, []);
                                if (newIsColumnAllowed.length > 0) {
                                    setIsKanbanAllowedToReceive(newIsColumnAllowed);
                                }
                            }}
                            onMonitorDragEnter={(event) => {
                                const { dragged } = event;

                                const newIsReceiving = [...isKanbanReceiving].reduce<boolean[]>((result, item, isReceivingIndex) => {
                                    if (index === isReceivingIndex) {
                                        result.push(!dragged.parentId?.includes(column.id));
                                    } else {
                                        result.push(false);
                                    }
                                    return result;
                                }, []);
                                setIsKanbanReceiving(newIsReceiving);
                            }}
                            onReceiveDragExit={() => {
                                const newIsReceiving = [...isKanbanReceiving].reduce<boolean[]>((result, item, isReceivingIndex) => {
                                    if (index === isReceivingIndex) {
                                        result.push(false);
                                    } else {
                                        result.push(item);
                                    }
                                    return result;
                                }, []);
                                setIsKanbanReceiving(newIsReceiving);
                            }}
                            onMonitorDragDrop={(event) => {
                                if (isKanbanReceiving[index] && !event.dragged.parentId?.includes(column.id)) {
                                    const { index: draggedIndex } = event.dragged.payload;

                                    const parentColumn = columns.find((columnFind) => event.dragged.parentId?.includes(columnFind.id));

                                    const draggedItem = parentColumn?.items[draggedIndex];

                                    column.setItems([...column.items, draggedItem]);
                                    parentColumn?.setItems(
                                        parentColumn?.items.reduce<string[]>((result, item) => {
                                            if (item !== draggedItem) {
                                                result.push(item);
                                            }
                                            return result;
                                        }, []),
                                    );
                                }
                            }}
                        >
                            <View>
                                {!isColumnOpen[index] && (
                                    <TouchableHighlight onPress={() => titlePress(index)}>
                                        <View
                                            style={[
                                                styles.title,
                                                { flexDirection: 'row', justifyContent: 'space-between' },
                                                isKanbanAllowedToReceive[index] && styles.titleAllowedToReceive,
                                                isKanbanReceiving[index] && styles.titleReceiving,
                                            ]}
                                        >
                                            <Text style={styles.titleText}>{formatMessage({ id: column.title })}</Text>
                                            <FontAwesome style={{ fontSize: 24, color: '#442772' }} name="arrow-circle-down" />
                                        </View>
                                    </TouchableHighlight>
                                )}
                                {isColumnOpen[index] && (
                                    <DraxList
                                        style={[styles.rowDrax]}
                                        reorderable
                                        scrollEnabled={false}
                                        id={`${column.id}-list`}
                                        longPressDelay={Platform.select({ web: 0, default: 100 })}
                                        data={column.items}
                                        renderItemContent={({ item, index: dragListIndex }, { viewState: itemViewState }) => {
                                            const draggingItem = itemViewState && itemViewState.dragStatus !== 0;
                                            return (
                                                <View id={`${column.id}-list-${dragListIndex}`} style={[styles.card, draggingItem && styles.cardDragging, dragListIndex !== 0 && { marginTop: 0 }]}>
                                                    <column.renderItem item={item} />
                                                </View>
                                            );
                                        }}
                                        onItemReorder={({ fromIndex, toIndex }) => {
                                            const newItems = column.items.slice();
                                            newItems.splice(toIndex, 0, newItems.splice(fromIndex, 1)[0]);
                                            column.setItems(newItems);
                                        }}
                                        keyExtractor={(_, dragListIndex) => `${column.id}-list-${dragListIndex}`}
                                        viewPropsExtractor={() => ({
                                            animateSnapback: false,
                                            onDragEnd: () => {
                                                setIsKanbanAllowedToReceive(columns.map(() => false));
                                                setIsKanbanReceiving(columns.map(() => false));
                                            },
                                            onDragDrop: () => {
                                                setIsKanbanAllowedToReceive(columns.map(() => false));
                                                setIsKanbanReceiving(columns.map(() => false));
                                            },
                                        })}
                                    />
                                )}
                            </View>
                        </DraxView>
                    </View>
                ))}
            </View>
        </DraxProvider>
    );
}

function Kanban({ columns, horizontal = false }: KanbanProps): JSX.Element {
    const { width } = useWindowDimensions();
    const small = width < 800;

    return (
        <ScrollView>
            <ScrollView horizontal contentContainerStyle={(horizontal || small) && { width: '100%' }}>
                {small ? <KanbanSmall columns={columns} /> : horizontal ? <KanbanHorizontal columns={columns} /> : <KanbanVertical columns={columns} />}
            </ScrollView>
        </ScrollView>
    );
}

export default Kanban;
